import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { filter, finalize, first, map, tap } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { DocumentPackageApiService } from '@npaApi/document-package-info.api.service';
import { DocumentApiService } from '@npaApi/document.api.service';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { DocumentPackagesStoreService } from '@npaCore/store/document-packages-store.service';
import { RefreshCurrentRouteComponent } from '@npaShared/components/refresh-current-route/refresh-current-route.component';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { VertexState } from '@npaShared/enums/vertex-state.enum';
import { getMostActivePointCurrentUser } from '@npaShared/helpers/route/get-active-points-current-user';
import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { RoutePoint } from '@npaShared/models/route/route.models';
import { HideBackdrop, ShowBackdrop } from '@store/visibility-backdrop/visibility-backdrop.action';

import { DocumentPackageStoreModel } from '@npaShared/models/document-package/document-package-store.model';
import { RoutePhaseTypes } from '../../document-agreement/enums/route-phase-types.enum';
import { AgreementNavigateService } from './agreement-navigate.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshRouteHelperService {
  constructor(
    private packagesStore: DocumentPackagesStoreService,
    private agreementNavigate: AgreementNavigateService,
    private modalController: ModalController,
    private documentPackageApi: DocumentPackageApiService,
    private documentApi: DocumentApiService,
    private store: Store,
    private currentUserStore: CurrentUserAissdStoreService,
  ) {}

  /** @returns true - надо обновить ПД */
  public checkActualInfoAboutDocumentPackage(dp?: DocumentPackageStoreModel): Observable<boolean> {
    const selectedDp = dp ?? this.packagesStore.getSelectedDocumentPackageSnapshot();

    this.store.dispatch(ShowBackdrop);
    console.log('4');

    return forkJoin([
      this.documentApi.searchByDocumentPackageId(selectedDp.id),
      this.documentPackageApi.getHasDiff(selectedDp.route.route.id, selectedDp.route.route.version),
    ]).pipe(
      map(([documents, hasDiff]) => {
        const hasChangesInDocuments = this.checkDocuments(documents, selectedDp.documents);
        return hasChangesInDocuments || hasDiff;
      }),
      tap((needUpdateRoute) => {
        if (needUpdateRoute) {
          this.showModalRefreshRoute();
        }
      }),
      finalize(() => this.store.dispatch(HideBackdrop)),
    );
  }

  /** @returns true - есть изменения в документах */
  private checkDocuments(
    newDocuments: DocumentInfoResponseModel[],
    currentDocuments: DocumentInfoResponseModel[],
  ): boolean {
    const newDocumentsId = newDocuments.map((item) => item.id);
    const currentDocumentsId = currentDocuments.map((item) => item.id);

    if (newDocumentsId.length !== currentDocumentsId.length) {
      return true;
    }

    const differenceNewDocuments = newDocumentsId.filter((x) => !currentDocumentsId.includes(x));
    const differenceCurrentDocuments = currentDocumentsId.filter((x) => !newDocumentsId.includes(x));

    const differenceArrays = differenceNewDocuments.concat(differenceCurrentDocuments);
    return differenceArrays.length > 0;
  }

  private async showModalRefreshRoute(): Promise<void> {
    const modal = await this.modalController.create({
      backdropDismiss: false,
      component: RefreshCurrentRouteComponent,
      cssClass: 'refresh-route',
    });

    modal.present();

    await modal.onDidDismiss();
    this.refreshInfo();
  }

  /** если у тек. пользователя есть еще активные точки в данном ПД, то обновить его, иначе обновить весь список ПД */
  private refreshInfo(): void {
    this.agreementNavigate.onUpdateSelectedDocumentAndGoBack();

    this.packagesStore
      .getSelectedDocumentPackage()
      .pipe(
        filter((dp) => !!dp),
        first(),
      )
      .subscribe((dp) => {
        if (!dp.route) {
          this.updateList();
          return;
        }

        const currentUser = this.currentUserStore.getCurrentUserSnapshot();
        const activePoint = getMostActivePointCurrentUser(dp.route, currentUser);

        if (!activePoint) {
          this.updateList();
          return;
        }

        const needUpdateListDP = this.needUpdateOnInitiationSubphase(activePoint);
        if (needUpdateListDP) {
          this.updateList();
        }
      });
  }

  private updateList(): void {
    this.agreementNavigate.redirectAfterDecision();
  }

  private needUpdateOnInitiationSubphase(activePoint: RoutePoint): boolean {
    /** на ОАУ только для точки руководителя со статусом waitingForApprovement не обновляем список ПД */
    if (activePoint.phaseTypeId === RoutePhaseTypes.oauPmInitiation) {
      return (
        activePoint.taskResolution === VertexState.waitingForApprovement &&
        activePoint.pointRoleId !== EmployeeRouteTypes.leader
      );
    }

    /** на этих подэтапах надо обновить список ПД, если точка со статусом waitingForApprovement */
    const phases = [
      RoutePhaseTypes.responsibleExecutorInitiation,
      RoutePhaseTypes.questionProvidedInitiation,
      RoutePhaseTypes.puPmInitiation,
      RoutePhaseTypes.projectApprovedInitiation,
      RoutePhaseTypes.preliminaryApprovementInitiation,
      RoutePhaseTypes.conciliationInitiation,
    ];
    return activePoint.taskResolution === VertexState.waitingForApprovement && phases.includes(activePoint.phaseTypeId);
  }
}
