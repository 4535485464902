import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { CaptureConsole, Offline } from '@sentry/integrations';
import { HttpStatusCode } from '@angular/common/http';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: environment.sentryDsn,
  ignoreErrors: [],
  integrations: [
    new CaptureConsole({
      levels: ['info', 'error'],
    }),
    new Offline(),
  ],
  environment: environment.envName,
  tracesSampleRate: 1.0,
  enabled: environment.enableSentry,
  normalizeDepth: 5,
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  beforeSend(event, hint) {
    /** TODO: вариант игнорирования 401 ошибок. как получится это сделать в ignoreErrors - тек. вариант можно будет удалить */
    if ((hint?.originalException as any)?.status === HttpStatusCode.Unauthorized) {
      return null;
    }

    return event;
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
