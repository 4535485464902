import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';

import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { removeItem } from '@ngxs/store/operators';
import { DocumentCardTypeEnum } from '@oogShared/enums/side-bar/document-card-type.enum';
import { CardDocumentModel } from '@oogShared/models/documents/card-document.model';
import { MenuRequestModel } from '@oogShared/models/documents/menu-request.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';

import {
  DeleteCard,
  ResetDocumentsCards,
  SetDocumentsCards,
  SetDocumentsCardsFromSearch,
  SetViewedCard,
} from './documents-cards.action';

@Injectable()
@State<CardDocumentModel[]>({
  name: 'documentsCards',
  defaults: [],
})
export class DocumentsCardsState {
  public static workForSelf(id: number, refresh: boolean): (state: CardDocumentModel[]) => MenuRequestModel[] {
    return createSelector([DocumentsCardsState], (state: CardDocumentModel[]): MenuRequestModel[] => {
      const resolutions = refresh ? 0 : state.filter((c) => c.cardType === DocumentCardTypeEnum.resolution).length;
      const approvals = refresh ? 0 : state.filter((c) => c.cardType === DocumentCardTypeEnum.approval).length;
      return [{ employeeId: id, skipResolutions: resolutions, skipApprovals: approvals }];
    });
  }

  public static workForEveryone(
    refresh: boolean,
    relevant: EmployeeModel[],
    authUser: EmployeeModel,
  ): (state: CardDocumentModel[]) => MenuRequestModel[] {
    return createSelector([DocumentsCardsState], (state: CardDocumentModel[]): MenuRequestModel[] => {
      const source = from(state);
      const group = [];
      const groupId = [];
      source
        .pipe(
          groupBy((c) => c.transferredRight?.onBehalfOfEmployeeId),
          mergeMap((g$) => g$.pipe(toArray())),
        )
        .subscribe((v) => {
          group.push(v);
        });

      if (!group?.length) {
        const emptyList = [];
        relevant.forEach((r) => {
          if (emptyList.find((e) => e.employeeId === r.id)) {
            return;
          }
          emptyList.push({
            employeeId: r.id,
            skipResolutions: 0,
            skipApprovals: 0,
          });
        });
        return emptyList;
      }

      let relevantList = [];

      group.forEach((a: CardDocumentModel[]) => {
        const item = a.filter((c) => {
          const index = relevant.findIndex((r) =>
            c.transferredRight ? r.id === c.transferredRight?.onBehalfOfEmployeeId : r.id === authUser.id,
          );
          return c.transferredRight ? c.transferredRight.onBehalfOfEmployeeId === relevant[index].id : c;
        });
        relevantList.push({
          employeeId: item[0].transferredRight ? item[0].transferredRight.onBehalfOfEmployeeId : authUser.id,
          skipResolutions: refresh ? 0 : item.filter((i) => i.cardType === 'resolution')?.length || 0,
          skipApprovals: refresh ? 0 : item.filter((i) => i.cardType === 'approval')?.length || 0,
        });
        if (item[0].transferredRight) {
          groupId.push(item[0].transferredRight.onBehalfOfEmployeeId);
        } else {
          groupId.push(authUser.id);
        }
      });

      if (relevantList.length !== relevant.length) {
        const arr = relevant.filter((r) => groupId.indexOf(r.id) < 0);
        const needItems = arr.map((a) => ({
          employeeId: a.id,
          skipResolutions: 0,
          skipApprovals: 0,
        }));
        relevantList = relevantList.concat(needItems);
      }

      return relevantList;
    });
  }

  @Selector()
  public static getCards(state: CardDocumentModel[]): CardDocumentModel[] {
    return state;
  }

  @Selector([ActiveCardState.getActiveCard])
  public static getCurrentAndTwoNextCards(state: CardDocumentModel[], active: CardDocumentModel): CardDocumentModel[] {
    const currentIndex = state.findIndex((item) => item.uniqueId === active.uniqueId);
    const idx = [currentIndex, currentIndex + 1, currentIndex + 2];
    return state.filter((_, i) => idx.includes(i));
  }

  @Selector()
  public static getFirstCard(state: CardDocumentModel[]): CardDocumentModel | null {
    return state?.length ? state[0] : null;
  }

  @Selector([ActiveCardState.getActiveCard])
  public static currentDocument(state: CardDocumentModel[], active: CardDocumentModel): number {
    return state.findIndex((item) => item.uniqueId === active.uniqueId);
  }

  @Selector([ActiveCardState.getActiveCard])
  public static mainDocumentInGroup(state: CardDocumentModel[], active: CardDocumentModel): CardDocumentModel | null {
    const mainCardId = active.groupProjects.groupProjects.mainAppealMovementId;
    return state.find((c) => c.appealMovementId === mainCardId) || null;
  }

  @Selector([ActiveCardState.getActiveCard])
  public static isAllViewedInGroup(state: CardDocumentModel[], active: CardDocumentModel): boolean {
    if (!active.groupProjects) {
      return false;
    }
    const mainCardId = active.groupProjects.groupProjects.mainAppealMovementId;
    const groups = state
      .filter((c) => c.groupProjects?.groupProjects?.mainAppealMovementId === mainCardId)
      ?.filter((c) => c.isNew);
    return !!groups.length;
  }

  @Selector()
  public static countDocuments(state: CardDocumentModel[]): number {
    return state.length;
  }

  @Selector()
  public static countResolution(state: CardDocumentModel[]): number {
    return state.filter((c) => c.cardType === DocumentCardTypeEnum.resolution).length;
  }

  @Selector()
  public static countApproval(state: CardDocumentModel[]): number {
    return state.filter((c) => c.cardType === DocumentCardTypeEnum.approval).length;
  }

  @Action(SetDocumentsCards)
  public setDocumentsCards(
    { getState, setState }: StateContext<CardDocumentModel[]>,
    { cards }: SetDocumentsCards,
  ): void {
    setState([...getState(), ...cards]);
  }

  @Action(SetDocumentsCardsFromSearch)
  public SetDocumentsCardsFromSearch(
    { setState }: StateContext<CardDocumentModel[]>,
    { cards }: SetDocumentsCardsFromSearch,
  ): void {
    setState(cards);
  }

  @Action(ResetDocumentsCards)
  public resetDocumentsCards({ setState }: StateContext<CardDocumentModel[]>): void {
    setState([]);
  }

  @Action(SetViewedCard)
  public setViewedCard({ getState, setState }: StateContext<CardDocumentModel[]>, { id }: SetViewedCard): void {
    const info = [...getState()];
    const cards = info.filter((c) => c.uniqueId === id);
    info.forEach((c, i) => {
      if (cards.includes(c)) {
        info[i] = {
          ...c,
          isNew: false,
        };
      }
    });
    setState(info);
  }

  @Action(DeleteCard)
  public deleteCard({ setState }: StateContext<CardDocumentModel[]>, { id }: DeleteCard): void {
    setState(removeItem((i) => i.uniqueId === id));
  }
}
