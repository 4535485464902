import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ControlsOog } from '@oogShared/components/controls';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { IonicModule } from '@ionic/angular';
import { SharedApplicationModule } from '@shared/shared-application.module';

import { HttpInterceptorCacheService } from '@oogShared/services/interceptor/http-interceptor-cache.service';
import { environment } from '../../../environments/environment';
import { ComponentsOog } from './components';
import { ConfirmDialog } from './components/confirm';
import { DirectivesOog } from './directives';
import { PipesOog } from './pipes';
import { HttpInterceptorHostChangeService } from './services/interceptor/http-interceptor-host-change.service';
import { HttpInterceptorModalService } from './services/interceptor/http-interceptor-modal.service';
import { HttpInterceptorSessionService } from './services/interceptor/http-interceptor-session.service';

@NgModule({
  imports: [
    IonicModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedApplicationModule,
    RouterModule,
    PdfViewerModule,
    PinchZoomModule,
  ],
  exports: [...ComponentsOog, ...DirectivesOog, ...PipesOog, ...ConfirmDialog, ...ControlsOog],
  declarations: [...ComponentsOog, ...DirectivesOog, ...PipesOog, ...ConfirmDialog, ...ControlsOog],
  providers: [
    { provide: 'BASE_API_URL', useValue: environment.host },
    ...PipesOog,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorHostChangeService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorSessionService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorModalService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorCacheService,
      multi: true,
    },
  ],
})
export class SharedOogModule {}
