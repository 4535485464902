import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Urls } from '@const/urls';
import { NetworkConnectionModel } from '@models/network-connection/network-connection.model';

@Injectable({
  providedIn: 'root',
})
export class NetworkConnectionInteropService {
  constructor(private http: HttpClient) {}

  public checkConnectionOog(): Observable<NetworkConnectionModel> {
    const url = Urls.backendStatus.checkConnectionOog;
    return this.http.get<NetworkConnectionModel>(url);
  }

  public checkConnectionNpa(): Observable<NetworkConnectionModel> {
    const url = Urls.backendStatus.checkConnectionNpa;
    return this.http.get<NetworkConnectionModel>(url);
  }
}
