import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Urls } from '@const/urls';
import { BaseApiResponseModel } from '@models/base-api-response.model';
import { BackendBuildInfoResponse } from '@models/settings/backend-build-info.model';
import { map } from 'rxjs/operators';

/**
 * Сервис получения информации о бекенде
 */
@Injectable({
  providedIn: 'root',
})
export class BackendBuildInfoService {
  constructor(private http: HttpClient) {}

  /**
   * Получить информацию о бекенде ИС ПРО
   */
  public getOog(): Observable<BackendBuildInfoResponse> {
    const url: string = Urls.backendBuildInfo.oog;
    return this.http.get<BaseApiResponseModel<BackendBuildInfoResponse>>(url).pipe(map((val) => val.data));
  }

  /**
   * Получить информацию о бекенде АИС СД
   */
  public getNpa(): Observable<BackendBuildInfoResponse> {
    const url: string = Urls.backendBuildInfo.npa;
    return this.http.get<BaseApiResponseModel<BackendBuildInfoResponse>>(url).pipe(map((val) => val.data));
  }
}
