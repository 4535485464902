import { Injectable } from '@angular/core';
import { UserInfoResponseModel } from '../../shared/models/user-info.model';
import { AbstractCurrentUserStore } from './abstract-current-user-store';
import { OibRolesEnum } from '@npaShared/enums/oib-roles.enum';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserAissdStoreService extends AbstractCurrentUserStore<UserInfoResponseModel> {
  public hasUserLpaAndNpaRights(): boolean {
    return [(OibRolesEnum.NPA_GOVERNMENT_USER, OibRolesEnum.NPA_INTERNAL_USER)].every((elem) =>
      this.getCurrentUserSnapshot()?.currentUser.roles.includes(elem),
    );
  }

  public hasUserLpaOrNpaRights(): boolean {
    return [(OibRolesEnum.NPA_GOVERNMENT_USER, OibRolesEnum.NPA_INTERNAL_USER)].some((elem) =>
      this.getCurrentUserSnapshot()?.currentUser.roles.includes(elem),
    );
  }
}
