import { Route, RoutePoint, RoutePointWithAdditionalInfo } from '@npaShared/models/route/route.models';
import { RoutePhaseTypes } from 'src/app/npa/document-agreement/enums/route-phase-types.enum';
import { DocumentsControlAlternatives } from '@npaShared/models/document/documents-control-alternatives.model';
import { nextPointOrCurrentPointIsLeader } from './point-helper';

/** нужна ли блокировка кнопок "Согласовать" и "Отправить на согласование" */
export const needDisabledButtonApprove = (
  route: Route,
  selectedPoint: RoutePoint | RoutePointWithAdditionalInfo,
  remarks: DocumentsControlAlternatives[],
): boolean => needDisableDecideButtons(route, selectedPoint) && Boolean(remarks?.length);

/** нужна ли блокировка кнопок "Согласовать с замечаниями" и "Отправить на согласование с замечаниями" */
export const needDisabledButtonApproveLuz = (
  route: Route,
  selectedPoint: RoutePoint | RoutePointWithAdditionalInfo,
  remarks: DocumentsControlAlternatives[],
): boolean => needDisableDecideButtons(route, selectedPoint) && !Boolean(remarks?.length);

/** нужна ли блокировка кнопок:
 * "Согласовать", "Согласовать с замечаниями",
 * "Отправить на согласование", "Отправить на согласование с замечаниями"
 */
export const needDisableDecideButtons = (route: Route, point: RoutePoint): boolean => {
  /** Этапы, на которых не нужна блокировка кнопок в зависимости от наличия замечаний */
  const phasesNotBlockingButtons = [
    RoutePhaseTypes.projectCreated,
    RoutePhaseTypes.projectCreatedApprovement,
    RoutePhaseTypes.projectCreatedDevelopment,
    RoutePhaseTypes.projectCreatedInitiation,
    RoutePhaseTypes.projectCreatedRework,
    RoutePhaseTypes.preliminaryApprovement,
    RoutePhaseTypes.preliminaryApprovementApprovement,
    RoutePhaseTypes.preliminaryApprovementInitiation,
    RoutePhaseTypes.preliminaryApprovementRevision,
    RoutePhaseTypes.oauRegistration,
    RoutePhaseTypes.oauRegistrationApprovement,
    RoutePhaseTypes.oauRegistrationInitiation,
  ];

  const isOnMainVertical = point.level === 1;
  const isDisabledButtonsPhase = !phasesNotBlockingButtons.includes(point.phaseTypeId);
  const isNextPointLeader = nextPointOrCurrentPointIsLeader(route, point);

  return isOnMainVertical && isDisabledButtonsPhase && isNextPointLeader;
};
