import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { ModalController } from '@ionic/angular';
import { DocumentPackageApiService } from '@npaApi/document-package-info.api.service';
import { DocumentPackagesStoreService } from '@npaCore/store/document-packages-store.service';
import { ButtonsTextEnum } from '@npaShared/enums/buttons-text.enum';
import { EmployeeRouteTypes } from '@npaShared/enums/employee-route-types.enum';
import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';
import { getDocumentRevision } from '@npaShared/helpers/document-revision';
import { ModalStartDevelopmentModel } from '@npaShared/models/modal/modal-start-development.model';
import { RefreshRouteHelperService } from '@npaShared/services/refresh-route-helper.service';
import { StartApprovementRequestModel } from '@npaShared/models/document-package/start-approvement-request.model';
import { UserSettingsStoreService } from '@npaCore/store/user-settings-store.service';
import { SettingName } from '@npaShared/enums/setting-name.enum';
import { DocumentRevisionModel } from '@npaShared/models/document/document-revision.model';
import { filteringBlankDocuments } from '@npaShared/functions/filtering-documents';
import { AgreementNavigateService } from '@npaShared/services/agreement-navigate.service';
import { Store } from '@ngxs/store';
import { ActiveCategoryState } from '@npaShared/store/active-category/active-category.state';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { Route } from '@npaShared/models/route/route.models';
import { getPointByIdWithAdditionalInfo } from '@npaShared/helpers/route/point-helper';
import { TypeMessageStartDevelopmentEnum } from '../../enums/type-message-start-development.enum';
import { RoutePhaseTypes } from '../../enums/route-phase-types.enum';
import { getMultipleActivePointLogic } from '../../../document-package/logic/multiple-active-points';
import { SelectedRoutePointService } from '../../../document-package/services/selected-route-point.service';

@Component({
  selector: 'app-start-development',
  templateUrl: './start-development.component.html',
  styleUrls: ['./start-development.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartDevelopmentComponent implements OnInit, OnDestroy {
  @Input() public data: ModalStartDevelopmentModel;

  public readonly buttonTheme = ButtonThemeEnum;
  public readonly buttonsText = ButtonsTextEnum;
  public readonly typeMessageStartDevelopmentEnum = TypeMessageStartDevelopmentEnum;

  public typeMessage: TypeMessageStartDevelopmentEnum = null;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store,
    private modalController: ModalController,
    private packagesStore: DocumentPackagesStoreService,
    private documentPackageApi: DocumentPackageApiService,
    private refreshRouteHelper: RefreshRouteHelperService,
    private settingsService: UserSettingsStoreService, // FIXME: МО-3320 выпилить как только актуализируется бэкенд на проде
    private activeRoutePointService: SelectedRoutePointService,
    private agreementNavigate: AgreementNavigateService,
    private currentUserStore: CurrentUserAissdStoreService,
  ) {}

  public ngOnInit(): void {
    this.chooseString();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async startDevelopment(): Promise<void> {
    const needRefresh = await this.refreshRouteHelper.checkActualInfoAboutDocumentPackage().toPromise();
    if (needRefresh) {
      this.onClose();
      return;
    }

    const selectedDp = this.packagesStore.getSelectedDocumentPackageSnapshot();
    const activePointWithAdditionalInfo = getPointByIdWithAdditionalInfo(selectedDp.route, this.data.pointId);
    const documents = filteringBlankDocuments(selectedDp.documents, activePointWithAdditionalInfo.phaseId);
    const revisions = documents.map((doc) => getDocumentRevision(doc, activePointWithAdditionalInfo));
    const data: StartApprovementRequestModel = { revisions, pointId: this.data.pointId };

    const startDevelopmentRequest$ =
      this.data.phaseTypeId === RoutePhaseTypes.oauPmInitiation
        ? this.documentPackageApi.startApprovement(this.data.routeId, data)
        : this.startDevelopmentRequest$(this.data.routeId, revisions);

    startDevelopmentRequest$
      .pipe(
        finalize(() => this.modalController.dismiss(true)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((updatedRoute) => {
        const activeCategory = this.store.selectSnapshot(ActiveCategoryState.getActiveCategory);
        const logic = getMultipleActivePointLogic(
          activeCategory,
          updatedRoute,
          this.currentUserStore.getCurrentUserSnapshot(),
        );
        const activePoints = logic.getActivePointsList();
        this.activeRoutePointService.setPotentialActivePointsList(activePoints);

        this.agreementNavigate.redirectAfterDecision();
      });
  }

  /** Закрытие окна */
  public onClose(): void {
    this.modalController.dismiss();
  }

  private chooseString(): void {
    const disallowedPhase = [RoutePhaseTypes.projectCreatedInitiation, RoutePhaseTypes.oauPmInitiation];
    this.typeMessage = null;

    if (
      this.data.pointRole === EmployeeRouteTypes.leader &&
      this.data.category === StatisticsEnum.mobileOfficeApprovementWrite &&
      !disallowedPhase.includes(this.data.phaseTypeId)
    ) {
      this.typeMessage = TypeMessageStartDevelopmentEnum.forExecution;
      return;
    }

    if (
      this.data.pointRole === EmployeeRouteTypes.leader &&
      this.data.category === StatisticsEnum.mobileOfficeApprovementWrite &&
      this.data.phaseTypeId === RoutePhaseTypes.oauPmInitiation
    ) {
      this.typeMessage = TypeMessageStartDevelopmentEnum.makeDecisionForSignature;
      return;
    }

    if (
      this.data.pointRole === EmployeeRouteTypes.executor &&
      this.data.category === StatisticsEnum.mobileOfficeWorkingExecutor &&
      this.data.phaseTypeId === RoutePhaseTypes.oauPmInitiation
    ) {
      this.typeMessage = TypeMessageStartDevelopmentEnum.makeDecisionWithoutSignature;
      return;
    }
  }

  // FIXME: МО-3320 выпилить как только актуализируется бэкенд на проде
  // из-за того, что на разных стендах разный бэкенд, то он требует различные дтошки на разных стендах
  // смысл этого костыля - проверить наличие настройки 'MO_PRE_GENERATE_PDF_ACTIVE' у пользователя
  // если данная настройка есть, то бэк умеет работать с обновленной ДТО
  // если настройки нет, то надо отправить ему старую ДТО
  private startDevelopmentRequest$(routeId: number, revisions: DocumentRevisionModel[]): Observable<Route> {
    const flag = 'MO_PRE_GENERATE_PDF_ACTIVE';
    const hasFlag = this.settingsService
      .getSettingsSnapshot()
      .find((setting) => setting.code === (flag as SettingName));

    return hasFlag
      ? this.documentPackageApi.startDevelopment(routeId, revisions, null)
      : this.documentPackageApi.startDevelopmentOld(routeId, revisions);
  }
}
