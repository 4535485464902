import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { UsersDataService } from '@npaCore/services/users-data.service';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { routeNames } from '../../../app-routing/route-names.const';

/** Гуард для страниц НПА-части приложения
 * происходит проверка на наличие в сторе пользователя НПА
 */
@Injectable({
  providedIn: 'root',
})
export class NpaAuthGuard implements CanActivate {
  constructor(
    private userService: UsersDataService,
    private userAissdStore: CurrentUserAissdStoreService,
    private navController: NavController,
  ) {}

  public canActivate(): Observable<boolean> {
    return this.userService.getNpaUser$().pipe(
      tap((user) => this.userAissdStore.setCurrentUser(user)),
      map((user) => !!user),
      catchError(() => of(false)),
      tap((hasPermission) => {
        if (!hasPermission) {
          // Если нет пользователю, то редиректю на главный экран
          this.navController.navigateRoot(routeNames.mainScreen);
        }
      }),
    );
  }
}
