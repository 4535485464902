import { Injectable } from '@angular/core';

import { defaultSettingsFormState } from '@const/default-settings-form.const';
import {
  CertificatesSettingsNpaModel,
  CertificatesSettingsOogModel,
} from '@models/settings/certificates-settings.model';
import { DocumentsSettingsModel } from '@models/settings/documents-settings.model';
import { NotificationModel } from '@models/settings/notification.model';
import { SettingsFormModel } from '@models/settings/settings-form.model';
import { SettingsResponseModel } from '@models/settings/settings-response/settings-response.model';
import { SubstituteModel } from '@models/settings/substitute.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetSettingsAcceleratedReview, SetSettings, SetSettingsCertificates } from './settings.action';

@Injectable()
@State<SettingsFormModel>({
  name: 'settings',
  defaults: {
    settingsForm: defaultSettingsFormState,
  },
})
export class SettingsState {
  @Selector()
  public static settingsNotification(state: SettingsFormModel): NotificationModel {
    return state.settingsForm.notification.model;
  }

  @Selector()
  public static showNotificationIcon(state: SettingsFormModel): boolean {
    const notificationSettings = state.settingsForm.notification.model;
    return Object.values(notificationSettings).some((item) => item.standard) && notificationSettings.notification;
  }

  @Selector()
  public static notificationValidDoc(state: SettingsFormModel): boolean {
    return state.settingsForm.notification.model.notificationValidDoc.standard;
  }

  @Selector()
  public static settingsDocuments(state: SettingsFormModel): DocumentsSettingsModel {
    return state.settingsForm.document.model;
  }

  @Selector()
  public static settingsAcceleratedReviewCategory(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.showAcceleratedReviewCategory;
  }

  @Selector()
  public static settingsSubstitute(state: SettingsFormModel): SubstituteModel {
    return state.settingsForm.substitute.model;
  }

  @Selector()
  public static showButtonFamiliarization(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.showDocumentReviewed;
  }

  @Selector()
  public static mainCommentInCardFullyOpened(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.mainCommentInCardFullyOpened;
  }

  @Selector()
  public static commentInApprovalBranchFullyOpened(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.commentInApprovalBranchFullyOpened;
  }

  @Selector()
  public static commentsInDossierAndDocumentBlocksFullyOpened(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.commentsInDossierAndDocumentBlocksFullyOpened;
  }

  @Selector()
  public static showPerformerListFullyOpened(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.showPerformerListFullyOpened;
  }

  @Selector()
  public static executionPeriodInheritance(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.executionPeriodInheritance;
  }

  @Selector()
  public static minimumDueDateAutoPreFill(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.minimumDueDateAutoPreFill;
  }

  @Selector()
  public static reduceExecutionPeriod(state: SettingsFormModel): number {
    return state.settingsForm.document.model.reduceExecutionPeriod;
  }

  @Selector()
  public static copyAllResolutionMissionBlocks(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.copyAllResolutionMissionBlocks;
  }

  @Selector()
  public static choiceActionOnCopyMission(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.choiceActionOnCopyMission;
  }

  @Selector()
  public static controlTypeInheritance(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.controlTypeInheritance;
  }

  @Selector()
  public static showButtonFolder(state: SettingsFormModel): boolean {
    return state.settingsForm.folders.model.showPersonalFolder;
  }

  @Selector()
  public static showButtonReports(state: SettingsFormModel): boolean {
    return state.settingsForm.reports.model.showReportsButton;
  }

  @Selector()
  public static certificateSettingsOog(state: SettingsFormModel): CertificatesSettingsOogModel {
    return state.settingsForm.certificates.model.oog;
  }

  @Selector()
  public static certificateSettingsNpa(state: SettingsFormModel): CertificatesSettingsNpaModel {
    return state.settingsForm.certificates.model.npa;
  }

  @Selector()
  public static commentsShouldBeOpened(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.showCommentsFullyOpened;
  }

  @Selector()
  public static settingsInBackendModel(state: SettingsFormModel): SettingsResponseModel {
    if (!state) {
      return;
    }
    return {
      notification: {
        notification: state.settingsForm.notification.model.notification,
        params: {
          notificationUrgentResolution: {
            push: state.settingsForm.notification.model.notificationUrgentResolution.push,
            standard: state.settingsForm.notification.model.notificationUrgentResolution.standard,
          },
          notificationUrgentProject: {
            push: state.settingsForm.notification.model.notificationUrgentProject.push,
            standard: state.settingsForm.notification.model.notificationUrgentProject.standard,
          },
          notificationUrgentDocument: {
            push: state.settingsForm.notification.model.notificationUrgentDocument.push,
            standard: state.settingsForm.notification.model.notificationUrgentDocument.push,
            time: state.settingsForm.notification.model.notificationUrgentDocument.time,
          },
          notificationControl: {
            push: state.settingsForm.notification.model.notificationControl.push,
            standard: state.settingsForm.notification.model.notificationControl.standard,
          },
          notificationValidDoc: {
            push: state.settingsForm.notification.model.notificationValidDoc.push,
            standard: state.settingsForm.notification.model.notificationValidDoc.standard,
          },
          notificationHide: {
            time: state.settingsForm.notification.model.notificationHide.time,
          },
          notificationErrorMessage: {
            push: state.settingsForm.notification.model.notificationErrorMessage.push,
            standard: state.settingsForm.notification.model.notificationErrorMessage.standard,
          },
          notificationSystemMessage: {
            push: state.settingsForm.notification.model.notificationSystemMessage.push,
            standard: state.settingsForm.notification.model.notificationSystemMessage.standard,
          },
        },
      },
      document: {
        showDocumentReviewed: state.settingsForm.document.model.showDocumentReviewed,
        showCommentsFullyOpened: state.settingsForm.document.model.showCommentsFullyOpened,
        mainCommentInCardFullyOpened: state.settingsForm.document.model.mainCommentInCardFullyOpened,
        commentInApprovalBranchFullyOpened: state.settingsForm.document.model.commentInApprovalBranchFullyOpened,
        commentsInDossierAndDocumentBlocksFullyOpened:
          state.settingsForm.document.model.commentsInDossierAndDocumentBlocksFullyOpened,
        showPerformerListFullyOpened: state.settingsForm.document.model.showPerformerListFullyOpened,
        showAcceleratedReviewCategory: state.settingsForm.document.model.showAcceleratedReviewCategory,
        executionPeriodInheritance: state.settingsForm.document.model.executionPeriodInheritance,
        minimumDueDateAutoPreFill: state.settingsForm.document.model.minimumDueDateAutoPreFill,
        reduceExecutionPeriod: state.settingsForm.document.model.reduceExecutionPeriod,
        copyAllResolutionMissionBlocks: state.settingsForm.document.model.copyAllResolutionMissionBlocks,
        choiceActionOnCopyMission: state.settingsForm.document.model.choiceActionOnCopyMission,
        controlTypeInheritance: state.settingsForm.document.model.controlTypeInheritance,
        recordAudioResolution: state.settingsForm.document.model.recordAudioResolution,
        paintGraphicResolution: state.settingsForm.document.model.paintGraphicResolution,
        typeTextResolution: state.settingsForm.document.model.typeTextResolution,
      },
      substitute: {
        showSubLeader: state.settingsForm.substitute.model.showSubLeader,
        showSubName: state.settingsForm.substitute.model.showSubName,
        autoCorrectSubActingName: state.settingsForm.substitute.model.autoCorrectSubActingName,
      },
      personalFolder: {
        editPersonalFolder: state.settingsForm.folders.model.showPersonalFolder
          ? state.settingsForm.folders.model.editPersonalFolder
          : false,
        showPersonalFolder: state.settingsForm.folders.model.showPersonalFolder,
      },
      reports: {
        showReportsButton: state.settingsForm.reports.model.showReportsButton,
      },
      certificates: {
        oog: {
          singingWithoutConfirmationOog: state.settingsForm.certificates.model.oog.singingWithoutConfirmationOog,
          defaultCertificateOog: state.settingsForm.certificates.model.oog.defaultCertificateOog,
          agreedOog: state.settingsForm.certificates.model.oog.agreedOog,
          signingResolutionOog: state.settingsForm.certificates.model.oog.signingResolutionOog,
        },
        npa: {
          singingWithoutConfirmationNpa: state.settingsForm.certificates.model.npa.singingWithoutConfirmationNpa,
          defaultCertificateNpa: state.settingsForm.certificates.model.npa.defaultCertificateNpa,
          agreedNpa: state.settingsForm.certificates.model.npa.agreedNpa,
        },
      },
    };
  }

  @Selector()
  public static editFolders(state: SettingsFormModel): boolean {
    return state.settingsForm.folders.model.editPersonalFolder;
  }

  @Selector()
  public static getGraphicStatus(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.paintGraphicResolution;
  }

  @Selector()
  public static getAudioStatus(state: SettingsFormModel): boolean {
    return state.settingsForm.document.model.recordAudioResolution;
  }

  @Action(SetSettings)
  public setSettings(
    { patchState, getState }: StateContext<SettingsFormModel>,
    { settingsForm, acceleratedRight }: SetSettings,
  ): void {
    if (!settingsForm) {
      return;
    }

    const state = getState();

    patchState({
      ...state,
      settingsForm: {
        ...state.settingsForm,
        notification: {
          ...state.settingsForm.notification,
          model: {
            notification: settingsForm.notification.notification,
            notificationUrgentResolution: settingsForm.notification.params.notificationUrgentResolution,
            notificationUrgentProject: settingsForm.notification.params.notificationUrgentProject,
            notificationUrgentDocument: settingsForm.notification.params.notificationUrgentDocument,
            notificationControl: settingsForm.notification.params.notificationControl,
            notificationValidDoc: settingsForm.notification.params.notificationValidDoc,
            notificationHide: settingsForm.notification.params.notificationHide,
            notificationErrorMessage: settingsForm.notification.params.notificationErrorMessage,
            notificationSystemMessage: settingsForm.notification.params.notificationSystemMessage,
          },
        },
        document: {
          ...state.settingsForm.document,
          model: {
            ...state.settingsForm.document.model,
            ...settingsForm.document,
            showAcceleratedReviewCategory: !acceleratedRight
              ? false
              : settingsForm.document.showAcceleratedReviewCategory,
            typeTextResolution: true,
          },
        },
        substitute: {
          ...state.settingsForm.substitute,
          model: {
            autoCorrectSubActingName: settingsForm.substitute.autoCorrectSubActingName,
            showSubLeader: settingsForm.substitute.showSubLeader,
            showSubName: settingsForm.substitute.showSubName,
          },
        },
        folders: {
          ...state.settingsForm.folders,
          model: {
            editPersonalFolder: settingsForm.personalFolder.editPersonalFolder,
            showPersonalFolder: settingsForm.personalFolder.showPersonalFolder,
          },
        },
        reports: {
          ...state.settingsForm.reports,
          model: {
            showReportsButton: settingsForm.reports.showReportsButton,
          },
        },
      },
    });
  }

  @Action(ResetSettingsAcceleratedReview)
  public resetSettingsAcceleratedReview({ patchState, getState }: StateContext<SettingsFormModel>): void {
    const state = getState();
    patchState({
      settingsForm: {
        ...state.settingsForm,
        document: {
          ...state.settingsForm.document,
          model: {
            ...state.settingsForm.document.model,
            showAcceleratedReviewCategory: false,
          },
        },
      },
    });
  }

  @Action(SetSettingsCertificates)
  public setSettingsCertificate(
    { patchState, getState }: StateContext<SettingsFormModel>,
    { settingsFormCertificate }: SetSettingsCertificates,
  ): void {
    if (!settingsFormCertificate) {
      return;
    }

    const state = getState();

    patchState({
      settingsForm: {
        ...state.settingsForm,
        certificates: {
          ...state.settingsForm.certificates,
          model: {
            oog: {
              singingWithoutConfirmationOog: settingsFormCertificate.oog.singingWithoutConfirmationOog,
              defaultCertificateOog: settingsFormCertificate.oog.defaultCertificateOog,
              agreedOog: settingsFormCertificate.oog.agreedOog,
              signingResolutionOog: settingsFormCertificate.oog.signingResolutionOog,
            },
            npa: {
              singingWithoutConfirmationNpa: settingsFormCertificate.npa.singingWithoutConfirmationNpa,
              defaultCertificateNpa: settingsFormCertificate.npa.defaultCertificateNpa,
              agreedNpa: settingsFormCertificate.npa.agreedNpa,
            },
          },
        },
      },
    });
  }
}
