import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { SharedApplicationModule } from '@shared/shared-application.module';
import { Components, EntryComponents } from './components/';
import { Controls } from './controls';
import { Directives } from './directives';
import { NpaStoreList } from './store';
import { Pipes } from './pipes';

@NgModule({
  imports: [
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedApplicationModule,
    NgxsModule.forFeature(NpaStoreList),
  ],
  declarations: [...Components, ...Directives, ...Pipes, ...Controls],
  providers: [DatePipe],
  exports: [...Components, ...Directives, ...Pipes, ...Controls],
  entryComponents: [...EntryComponents],
})
export class SharedModule {}
