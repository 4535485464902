import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { DeviceTypeEnum } from '@enums/device-type.enum';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Platform } from '@ionic/angular';
import { DeviceAdditionalInfo } from '@models/login/device-additional-info.model';
import { DeviceInfoModel } from '@models/login/device-info.model';
import { AuthApiService } from '@npaApi/auth.api.service';
import { hasNpaCredentials, hasOogCredentials } from '@shared/functions/set-users-for-sentry.function';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService {
  private uuidKey = 'UUID_KEY';

  constructor(private platform: Platform, private device: Device, private api: AuthApiService) {}

  public getDeviceGuid(): string {
    if (this.device.uuid) {
      return this.device.uuid;
    }

    if (localStorage.getItem(this.uuidKey)) {
      return localStorage.getItem(this.uuidKey);
    }

    localStorage.setItem(this.uuidKey, uuidv4());

    return localStorage.getItem(this.uuidKey);
  }

  public getVersion(): string {
    return this.device.version ?? window.navigator.userAgent;
  }

  public getDeviceType(): DeviceTypeEnum {
    if (!this.platform.is('capacitor')) {
      return DeviceTypeEnum.WEB;
    }

    if (this.platform.is('ios')) {
      return DeviceTypeEnum.IOS;
    }

    if (this.platform.is('android')) {
      return DeviceTypeEnum.ANDROID;
    }
  }

  public saveDeviceInfo(): void {
    const guid = this.getDeviceGuid();
    const type = this.getDeviceType();
    const osVersion = this.getVersion();
    const moVersion = environment.mobileVersion;
    const resolution = `${this.platform.width()}x${this.platform.height()}`;
    // TODO: добавить возможность получения дополнительной иформации об устройстве и добавить её в это поле
    const additionalInfo: DeviceAdditionalInfo = {};

    const deviceInfoModel: DeviceInfoModel = {
      guid,
      token: null,
      type,
      OSVersion: osVersion,
      MOVersion: moVersion,
      resolution,
      additionalInfo,
    };

    const token = localStorage.getItem('token');
    deviceInfoModel.token = JSON.parse(token)?.value || null;
    this.customSaveDeviceInfo(deviceInfoModel);
  }

  private customSaveDeviceInfo(deviceInfo: DeviceInfoModel): void {
    if (hasOogCredentials()) {
      this.api.saveDeviceInfoIsPro(deviceInfo).pipe(first()).subscribe();
    }

    if (hasNpaCredentials()) {
      this.api.saveDeviceInfoAisSd(deviceInfo).pipe(first()).subscribe();
    }
  }
}
