import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DictionaryModel } from '../../models/dictionary.model';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputSearchComponent), multi: true }],
})
export class InputSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  /** Текст метки */
  @Input() public label: string;
  /** Текст плейсхолдера */
  @Input() public placeholder = '';
  /** Список элементов */
  @Input() public items: DictionaryModel[];
  @Input() public controlInvalid = false;

  /** Оповещение о событии изменения текста поиска */
  @Output() public searchTextChanged = new EventEmitter<string>();

  @ViewChild('searchInputControl', { static: true }) public searchInputControl: ElementRef;

  /** Флаг показывается ли список */
  public isListShowed: Boolean;
  /** Выбранное значение */
  public value: DictionaryModel;
  /** Флаг активности контрола */
  public disabled: boolean;
  /** Форма для инпута */
  public inputControl = new FormControl(null);

  private searchTextChangedSubscription: Subscription;

  public focus(): void {
    this.onTouched();
  }

  public ngOnInit(): void {
    this.subscribeToSearchTextChanged();
  }

  public ngOnDestroy(): void {
    this.searchTextChangedSubscription.unsubscribe();
  }

  public writeValue(value: DictionaryModel): void {
    this.close();
    this.value = value;
    this.onChange(value);
    this.inputControl.setValue(value ? value.name : null);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Показать список */
  public showList(): void {
    if (this.value) {
      this.inputControl.setValue(this.value.name);
    }

    this.isListShowed = true;
    setTimeout(() => {
      this.searchInputControl.nativeElement.focus();
    });
  }

  /** Скрыть список */
  public close(): void {
    this.isListShowed = false;
  }

  /** Выполнить очистку контрола */
  public clear(): void {
    this.items = [];
    this.value = null;
    this.inputControl.setValue(null);
    this.onChange(null);
  }

  public onTouched = (): any => {};

  private onChange = (_value: DictionaryModel): any => {};

  private subscribeToSearchTextChanged(): void {
    this.searchTextChangedSubscription = this.inputControl.valueChanges.subscribe((value) =>
      this.searchTextChanged.emit(value),
    );
  }
}
