import { Pipe, PipeTransform } from '@angular/core';

export enum TypesDeclination {
  days = 'days',
}

@Pipe({
  name: 'declinationWords',
})
export class DeclinationWordsPipe implements PipeTransform {
  private listDeclination = [
    {
      type: TypesDeclination.days,
      aray: ['день', 'дня', 'дней'],
    },
  ];

  /**
   * надо передать тип склонения,
   * например 'days' - день, дня, дней
   *
   * если типа нет, то вернёт число
   */
  public transform(num: number, type: TypesDeclination): string | number {
    const itemDeclination = this.listDeclination.find((i) => i.type === type);

    if (!itemDeclination) {
      return num;
    }

    return `${num} ${
      itemDeclination.aray[num % 100 > 4 && num % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][num % 10 < 5 ? num % 10 : 5]]
    }`;
  }
}
