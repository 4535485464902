import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserInfoApiService } from '@npaApi/user-info.api.service';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { CurrentUserModel } from '@oogShared/models/current-user/current-user.model';
import { SettingsCertificateService } from '@shared/services/certificates/settings-certificate.service';
import { SetRightAcceleratedReview } from '@store/right-accelerated-review/right-accelerated-review.action';
import { SetRightsForRedirectionExpeditedReview } from '@store/right-for-redirection-expedited-review/rights-for-redirection-expedited-review.action';
import { SetSettings, SetSettingsCertificates } from '@store/settings/settings.action';
import { SetStatusCurrentUserAssistant } from '@store/status-current-user-assistant/status-current-user-assistant.action';
import { forkJoin, Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { SetHasDssCurrentUser } from '@store/has-dss-current-user/has-dss-current-user.action';
import { RelevantWorkersService } from '@oogShared/services/relevant-workers.service';

@Injectable({ providedIn: 'root' })
export class BaseAuthGuardService {
  constructor(
    protected userApi: UserInfoApiService,
    protected userIsproStore: CurrentUserIsproStoreService,
    protected authUserIspro: AuthUserIsproStoreService,
    protected store: Store,
    protected settingsCertificateService: SettingsCertificateService,
    private userAissdStore: CurrentUserAissdStoreService,
    private relevantWorkersService: RelevantWorkersService,
  ) {}

  public getUsersFromStoreOrApi(
    isPro$: Observable<CurrentUserModel | null>,
    aisSd$: Observable<UserInfoResponseModel | null>,
  ): Observable<[UserInfoResponseModel, CurrentUserModel]> {
    return forkJoin([aisSd$, isPro$]).pipe(
      tap(([npaUser, oogUser]) => {
        this.userAissdStore.setCurrentUser(npaUser);
        this.userIsproStore.setCurrentUser(oogUser?.employee);

        const settingsCertificates = this.settingsCertificateService.allSettings;
        this.store.dispatch([
          new SetSettings(oogUser?.settings, oogUser?.hasAcceleratedReview),
          new SetSettingsCertificates(settingsCertificates),
          new SetRightsForRedirectionExpeditedReview(oogUser?.acceleratedReviewEmployees),
          new SetRightAcceleratedReview(oogUser?.hasAcceleratedReview),
          new SetStatusCurrentUserAssistant(oogUser?.hasAssistant),
          new SetHasDssCurrentUser(oogUser?.hasDss),
        ]);

        if (!this.authUserIspro.getCurrentUserSnapshot()) {
          this.authUserIspro.setCurrentUser(oogUser?.employee);
        }

        this.replacedUser();
      }),
    );
  }

  public replacedUser(): void {
    this.userApi
      .replacedUser()
      .pipe(
        first(),
        filter((data) => !!data),
      )
      .subscribe((data) => this.relevantWorkersService.setRelevantWorkers(data));
  }
}
