import { VisibilityContextMenuInButtonsDecisionState } from './visibility-context-menu-in-buttons-decision/visibility-context-menu-in-buttons-decision.state';
import { DocumentRouteVisibilityState } from './document-route-visibility/document-route-visibility.state';
import { DocumentPackagesState } from './document-packages/document-packages.state';
import { ActiveCategoryState } from './active-category/active-category.state';
import { ProjectGroupTypeState } from './project-group-type/project-group-type.state';
import { DecisionActionsAvailabilityState } from './decision-actions-availability/decision-actions-availability.state';

export const NpaStoreList = [
  DocumentRouteVisibilityState,
  VisibilityContextMenuInButtonsDecisionState,
  DocumentPackagesState,
  ActiveCategoryState,
  ProjectGroupTypeState,
  DecisionActionsAvailabilityState,
];
