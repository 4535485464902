import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { BaseAuthGuardService } from '@shared/services/base-auth-guard.service';
import { SettingsCertificateService } from '@shared/services/certificates/settings-certificate.service';
import { SetRightAcceleratedReview } from '@store/right-accelerated-review/right-accelerated-review.action';
import { SetRightsForRedirectionExpeditedReview } from '@store/right-for-redirection-expedited-review/rights-for-redirection-expedited-review.action';
import { SetStatusCurrentUserAssistant } from '@store/status-current-user-assistant/status-current-user-assistant.action';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { SetSettings, SetSettingsCertificates } from '@store/settings/settings.action';
import { RelevantWorkersService } from '@oogShared/services/relevant-workers.service';
import { UserInfoApiService } from '@npaApi/user-info.api.service';
import { EmployeeModel } from '../shared/models/resolutions/employee/employee.model';

@Injectable({
  providedIn: 'root',
})
export class RouteDocumentsGuard extends BaseAuthGuardService implements CanActivate {
  constructor(
    userApi: UserInfoApiService,
    userAissdStore: CurrentUserAissdStoreService,
    userIsproStore: CurrentUserIsproStoreService,
    authUserIspro: AuthUserIsproStoreService,
    store: Store,
    settingsCertificateService: SettingsCertificateService,
    relevantWorkersService: RelevantWorkersService,
    private navController: NavController,
  ) {
    super(
      userApi,
      userIsproStore,
      authUserIspro,
      store,
      settingsCertificateService,
      userAissdStore,
      relevantWorkersService,
    );
  }

  public canActivate(): Observable<boolean> {
    return this.getFromStoreOrAPI().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  private getFromStoreOrAPI(): Observable<EmployeeModel> {
    return this.userIsproStore.getCurrentUser().pipe(
      tap((userInfo) => {
        if (!userInfo) {
          this.userApi.getIsProInfo().subscribe(
            (res) => {
              this.userIsproStore.setCurrentUser(res?.employee);
              this.authUserIspro.setCurrentUser(res?.employee);

              const settingsCertificates = this.settingsCertificateService.allSettings;
              this.store.dispatch([
                new SetSettings(res?.settings, res?.hasAcceleratedReview),
                new SetSettingsCertificates(settingsCertificates),
                new SetRightsForRedirectionExpeditedReview(res?.acceleratedReviewEmployees),
                new SetRightAcceleratedReview(res?.hasAcceleratedReview),
                new SetStatusCurrentUserAssistant(res?.hasAssistant),
              ]);
              this.replacedUser();
            },
            () => {
              this.navController.navigateRoot('/auth/login').then();
            },
          );
        }
      }),
      filter((userInfo) => !!userInfo),
      first(),
    );
  }
}
