import { Injectable } from '@angular/core';
import { filter, finalize, first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ResetForm } from '@store/forms/forms.action';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetActiveCardProjectResolution } from '@store/resolution-store/active-resolution-project/active-resolution-project.action';
import { ResetDossier } from '@store/resolution-store/dossier/dossier.action';
import { ResetResolution } from '@store/resolution-store/resolution/resolution.action';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { DraftsInteropService } from '@oogShared/rest/drafts-interop.service';
import { AppealResolutionEnum } from '@oogShared/enums/resolution-form/appeal-resolution-type.enum';
import { ResolutionTypeModel } from '@oogShared/models/resolutions/resolution/resolution-type.model';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';
import { modalIdSaveResolution } from '@const/modals-id.const';
import { ResolutionDraftStorageService } from '@oogShared/rest/resolution-draft-storage.service';
import { AddresseeTypesEnum } from '../../enums/addressee-types.enum';
import { AppealResolutionEditModel, ResolutionEditModel } from '../../models/forms/forms.model';
import { PerformerFormModel } from '../../models/forms/performer-form.model';
import { MissionModel } from '../../models/resolutions/mission/mission.model';
import { ResolutionModel } from '../../models/resolutions/resolution/resolution.model';
import { ResolutionInteropService } from '../../rest/resolution-interop.service';
import { DocumentNavigationService } from '../document-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class MapperBaseService {
  private requestInProcess = false;

  constructor(
    protected resolutionInterop: ResolutionInteropService,
    protected store: Store,
    protected modalCtrl: ModalController,
    protected draftsInteropService: DraftsInteropService,
    protected documentNavigationService: DocumentNavigationService,
    protected resolutionDraftStorageService: ResolutionDraftStorageService,
  ) {}

  /** утвердить проект резолюции */
  protected acceptResolution(resolution: ResolutionModel): void {
    if (this.requestInProcess) {
      return;
    }

    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);

    this.requestInProcess = true;
    this.resolutionInterop
      .acceptProjectResolution(resolution, card)
      .pipe(
        first(),
        finalize(() => (this.requestInProcess = false)),
        filter((r) => !!r),
      )
      .subscribe(() => {
        const activeResolution = this.store.selectSnapshot(ResolutionState.getResolutionProjectCard);
        this.resolutionDraftStorageService.removeResolutionDraft(activeResolution);
        this.draftsInteropService.saveDraft(activeResolution);
        this.documentNavigationService.nextDocument();
        this.store.dispatch([ResetResolution, ResetForm, ResetActiveCardProjectResolution, ResetDossier]);
        this.modalCtrl.dismiss(undefined, undefined, modalIdSaveResolution).then();
      });
  }

  protected addEmptyExecutor(form: ResolutionEditModel, commissions: MissionModel[]): MissionModel[] {
    const hasEmptyPerformer = this.hasEmptyPerformer(commissions);

    if (hasEmptyPerformer) {
      return commissions;
    }

    const indexes = [];

    form.commissions.forEach((c, i) => {
      if (c.executorSign) {
        indexes.push(i);
      }
    });
    indexes.forEach((i) => {
      commissions[i]?.addressee.push({
        addresseeType: {
          id: AddresseeTypesEnum.executor,
          name: 'Исполнитель',
        },
        checkDateType: null,
        decontrolDate: null,
        dueDate: null,
        employee: null,
        forPrepareAnswer: null,
        initialDueDate: null,
        isControl: false,
        isResponsibleByMission: false,
        missionAction: null,
        reportDate: null,
        serialNumber: commissions[i].addressee.length + 1,
      });
    });

    return commissions;
  }

  protected addEmptyExecutorToAppealMissions(
    form: AppealResolutionEditModel,
    commissions: MissionModel[],
  ): MissionModel[] {
    const hasEmptyPerformer = this.hasEmptyPerformer(commissions);

    if (hasEmptyPerformer) {
      return commissions;
    }

    const indexes = [];

    const missions = form.questions.reduce((acc, cur) => acc.concat(cur.commissions), []);

    missions.forEach((c, i) => {
      if (c.executorSign) {
        indexes.push(i);
      }
    });
    indexes.forEach((i) => {
      commissions[i]?.addressee.push({
        addresseeType: {
          id: AddresseeTypesEnum.executor,
          name: 'Исполнитель',
        },
        checkDateType: null,
        decontrolDate: null,
        dueDate: null,
        employee: null,
        forPrepareAnswer: null,
        initialDueDate: null,
        isControl: false,
        isResponsibleByMission: false,
        missionAction: null,
        reportDate: null,
        serialNumber: commissions[i].addressee.length + 1,
      });
    });

    return commissions;
  }

  protected getValueControl(performer: PerformerFormModel): boolean | null {
    if (performer.control) {
      return performer.control;
    }

    if (performer.actionControl) {
      return true;
    }

    return null;
  }

  protected getCommissionType(value: AppealResolutionEnum): ResolutionTypeModel {
    switch (value) {
      case AppealResolutionEnum.resolution:
        return { id: ResolutionTypeEnum.resolution, name: 'Резолюция' };
      case AppealResolutionEnum.project:
        return { id: ResolutionTypeEnum.request, name: 'Запрос' };
      case AppealResolutionEnum.redirect:
        return { id: ResolutionTypeEnum.forwarding, name: 'Переадресация' };

      default:
        throw new Error('Не подходящий тип поручения');
    }
  }

  private hasEmptyPerformer(commissions: MissionModel[]): boolean {
    return !!commissions.find((com) => !!com.addressee.find((addressee) => !addressee.employee));
  }
}
