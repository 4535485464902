import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { UserGuidePageComponent } from '@components/user-guide-page/user-guide-page.component';
import { AuthGuard } from '@npaCore/guards/auth-guard.service';
import { NpaAuthGuard } from '@npaCore/guards/npa-auth-guard.service';
import { AppVersionGuard } from '@shared/guards/app-version-guard.service';
import { SettingsGuardService } from '@shared/guards/settings-guard.service';
import { RouteDocumentsGuard } from '../oog/guard/route-documents.guard';
import { ReportsGuard } from '../oog/guard/reports.guard';
import { routeNames } from './route-names.const';

const routes: Routes = [
  {
    path: '',
    redirectTo: routeNames.mainScreen,
    pathMatch: 'full',
  },
  {
    path: routeNames.mainScreen,
    loadChildren: () => import('../shared/main-screen/main-screen.module').then((m) => m.MainScreenModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeNames.reportsList,
    loadChildren: () => import('../oog/reports-page/reports-page.module').then((m) => m.ReportsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeNames.documentPackage,
    loadChildren: () => import('../npa/document-package/document-package.module').then((m) => m.DocumentPackageModule),
    canActivate: [NpaAuthGuard],
  },
  {
    path: routeNames.documentPreview,
    loadChildren: () => import('../npa/document-preview/document-preview.module').then((m) => m.DocumentPreviewModule),
    canActivate: [NpaAuthGuard],
  },
  {
    path: routeNames.documentAgreement,
    loadChildren: () =>
      import('../npa/document-agreement/document-agreement.module').then((m) => m.DocumentAgreementModule),
    canActivate: [NpaAuthGuard],
  },
  {
    path: routeNames.auth,
    loadChildren: () => import('../shared/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AppVersionGuard],
  },
  {
    path: routeNames.appUpdate,
    loadChildren: () => import('../shared/app-update/app-update.module').then((m) => m.AppUpdateModule),
  },
  {
    path: routeNames.documents,
    loadChildren: () => import('../oog/documents-page/documents.module').then((m) => m.DocumentsModule),
    canActivate: [RouteDocumentsGuard],
  },
  {
    path: routeNames.reports,
    loadChildren: () => import('../oog/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard, ReportsGuard],
  },
  {
    path: routeNames.expeditedReview,
    loadChildren: () => import('../oog/expedited-review/expedited-review.module').then((m) => m.ExpeditedReviewModule),
    canActivate: [RouteDocumentsGuard],
  },
  {
    path: routeNames.documentForm,
    loadChildren: () => import('../oog/document-forms/document-forms.module').then((m) => m.DocumentFormsModule),
    canActivate: [RouteDocumentsGuard],
  },
  {
    path: routeNames.settings,
    loadChildren: () => import('../shared/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [SettingsGuardService],
  },
  {
    path: routeNames.userGuide,
    component: UserGuidePageComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
