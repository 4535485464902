import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LoginPassStorageService } from '@shared/services/login-pass-storage.service';

import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputPasswordComponent), multi: true }],
})
export class InputPasswordComponent extends InputComponent implements ControlValueAccessor {
  @Input() public hasError = false;
  @Input() public hasSuccess = false;
  @Input() public isIsPro = false;

  @Output() public controlValueChanges = new EventEmitter<string>();

  public isShowPassword: boolean;
  public inputControl = new FormControl(null);

  constructor(private loginPassStorageService: LoginPassStorageService) {
    super();
  }

  public patchValue(value: string): void {
    this.writeValue(value);
    this.controlValueChanges.emit(value);
  }

  /** Принимать события заморозки/разморозки извне */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.inputControl.disable();
      return;
    }

    this.inputControl.enable();
  }

  public clear(): void {
    this.patchValue('');
    this.isIsPro
      ? this.loginPassStorageService.updateData('oogData', { password: null })
      : this.loginPassStorageService.updateData('aissdData', { password: null });
  }
}
