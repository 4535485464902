import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

import { RadioItemFilterComponent } from './filter-item/radio-item-filter.component';

@Component({
  selector: 'app-radio-group-buttons',
  templateUrl: './radio-group-buttons.component.html',
  styleUrls: ['./radio-group-buttons.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupButtonsComponent),
      multi: true,
    },
  ],
})
export class RadioGroupButtonsComponent implements ControlValueAccessor, AfterContentInit, AfterViewInit {
  @Input() public filters: any[];
  @ContentChildren(RadioItemFilterComponent, { descendants: true, read: false })
  public options: QueryList<RadioItemFilterComponent>;

  public value: any;

  private ngUnsubscribe$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  public ngAfterContentInit(): void {
    this.subscribeToOptionClick();
  }

  public ngAfterViewInit(): void {
    this.colorSelectedItemByValue(this.value);
  }

  public writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
    if (this.options) {
      this.colorSelectedItemByValue(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (value: any): any => {};

  private onTouched = (): any => {};

  private colorSelectedItemByValue(value: any): void {
    this.unColorAll();
    const selected: RadioItemFilterComponent = this.options.find((comp) => comp.value === value);
    if (!selected) {
      return;
    }
    setTimeout(() => {
      selected.isActive = true;
      this.cdr.markForCheck();
    });
  }

  private unColorAll(): void {
    this.options?.forEach((option) => (option.isActive = false));
  }

  private subscribeToOptionClick(): void {
    this.options.changes
      .pipe(
        startWith(this.options),
        switchMap((items: QueryList<RadioItemFilterComponent>) => merge(...items.map((c) => c.selectEvent))),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe((value) => this.writeValue(value));
  }
}
