import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';
import { SelectTypeModel } from '@models/dictionaries/select-type.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';

import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { changeUserSession } from '@oogShared/functions/change-user-session.function';
import { FormResolutionModel } from '@oogShared/models/form-pages/form-resolution.model';
import { EmployeeModel } from '@oogShared/models/resolutions/employee/employee.model';
import { MissionModel } from '@oogShared/models/resolutions/mission/mission.model';
import { ResolutionAddresseeModel } from '@oogShared/models/resolutions/resolution/resolution-addressee.model';
import { ResolutionModel } from '@oogShared/models/resolutions/resolution/resolution.model';
import { DictionariesInteropService } from '@oogShared/rest/dictionaries-interop.service';
import { DischargeDutiesService } from '@oogShared/services/discharge-of-duties/discharge-duties.service';
import { FormActionControlService } from '@oogShared/services/forms/form-action-control.service';
import { FormCreateAppealResolutionService } from '@oogShared/services/forms/form-create-appeal-resolution.service';
import { flatMap } from '@shared/functions/flat-map.function';
import { DateHelperService } from '@shared/services/date-helper.service';
import { DictionariesTemplateState } from '@store/dictionaries/dictionaries-templates/dictionaries-template.state';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';
import { SettingsState } from '@store/settings/settings.state';
import { StatusCurrentUserAssistantState } from '@store/status-current-user-assistant/status-current-user-assistant.state';
import { Observable } from 'rxjs';
import { RadioBorderEnum } from '@oogShared/enums/radio-border.enum';
import { RadioFlexEnum } from '@oogShared/enums/radio-flex.enum';
import { RadioControlEnum } from '@oogShared/enums/radio-control.enum';
import { EsignOogService } from '@oogShared/services/esign-oog.service';
import { CreateResolutionDraftService } from '@oogShared/services/forms/draft/create-resolution-draft.service';
import { DictionaryTemplateTypes } from '@enums/dictionary-template-types/dictionary-template-types.enums';
import { ParentMissionData } from '../resolution-create/parent-mission-data.model';
import { GraphicHelperService } from '../graphic-form/services/graphic-helper.service';
import { AppealFormBase } from '../appeal-form-base';

// Название контрола с данными из родительского поручения
const PARENT_COMMISSION_DATA = 'parentCommissionData';

@UntilDestroy()
@Component({
  selector: 'app-appeal-resolution-create',
  templateUrl: './appeal-resolution-create.page.html',
  styleUrls: ['./appeal-resolution-create.page.scss'],
})
export class AppealResolutionCreatePage extends AppealFormBase implements OnInit {
  @Select(ResolutionState.getResolutionProjectCard)
  public resolution$: Observable<ResolutionModel>;

  @Select(
    DictionariesTemplateState.templates([DictionaryTemplateTypes.commonDefault, DictionaryTemplateTypes.resolution]),
  )
  public templates$!: Observable<SelectTypeModel[]>;

  @Select(StatusCurrentUserAssistantState.getStatus)
  public statusAssistant$!: Observable<boolean>;

  public showInvalidPerformers = false;

  public readonly radioTheme = RadioControlEnum;
  public readonly radioBorderType = RadioBorderEnum;
  public readonly radioFlexType = RadioFlexEnum;

  /** значение настройки Автоматического предзаполнения минимального срока исполнения */
  private readonly minimumDueDateAutoPreFillSetting = this.store.selectSnapshot(
    SettingsState.minimumDueDateAutoPreFill,
  );

  constructor(
    actionControlService: FormActionControlService,
    fb: FormBuilder,
    store: Store,
    dateHelper: DateHelperService,
    restoreFormService: CreateResolutionDraftService,
    modalCtrl: ModalController,
    dictionariesInterop: DictionariesInteropService,
    cdr: ChangeDetectorRef,
    dischargeService: DischargeDutiesService,
    currentUser: CurrentUserIsproStoreService,
    private formService: FormCreateAppealResolutionService,
    private graphicHelperService: GraphicHelperService,
    private esignService: EsignOogService,
  ) {
    super(
      store,
      fb,
      dateHelper,
      currentUser,
      restoreFormService,
      modalCtrl,
      actionControlService,
      dictionariesInterop,
      cdr,
      dischargeService,
    );
  }

  public ngOnInit(): void {
    changeUserSession(this.store, this.currentUser);
    this.formService.mapDataToForm();
    this.headerData = this.formService.mapFormHeader(this.formService.dataForm);
    this.initForm(this.formService.dataForm);
  }

  public createResolution(): void {
    this.dispatchForm = true;
    this.clearFormDispatching();

    if (this.form.invalid) {
      this.showInvalidPerformers = true;
      setTimeout(() => (this.showInvalidPerformers = false), 2000);
      return;
    }

    if (this.esignService.isInvalidCertificateSigningResolution()) {
      this.esignService.showModalInvalidCertificate();
      return;
    }
    this.addresseeInCommissions() ? this.emptyCommissions() : this.formService.createResolution(this.questionIds);
  }

  /** Возвращает true, если хотя бы в одном поручении есть контрол с индексом родительской резолюции */
  public someCommissionFormContainParentMissionIndexControl(): boolean {
    const commissions = (this.form.get('questions') as FormArray).controls.reduce(
      (acc, cur) => acc.concat(cur.get('commissions')),
      [],
    );
    return commissions.some((commissionForm) => this.parentMissionIndexExist(commissionForm as FormGroup));
  }

  /** Заменить данные поручения данными из родительской резолюции */
  public replaceResolutionWithParentResolution(questionForm: AbstractControl, commissionForm: AbstractControl): void {
    this.patchDataToForm(questionForm, commissionForm, this.parentMissionData(commissionForm), true);

    this.removeParentCommissionIndex(commissionForm);
  }

  /** Дополнить данные поручения данными из родительской резолюции */
  public supplementResolutionWithParentResolution(
    questionForm: AbstractControl,
    commissionForm: AbstractControl,
  ): void {
    this.patchDataToForm(questionForm, commissionForm, this.parentMissionData(commissionForm), false, true);

    this.removeParentCommissionIndex(commissionForm);
  }

  /** Не копировать данные родительской резолюции */
  public notCopyResolutionWithParentResolution(commissionForm: AbstractControl): void {
    this.removeParentCommissionIndex(commissionForm);
  }

  public chooseUser(
    question: AbstractControl,
    questionIdx: number,
    commissionIdx: number,
    employee: EmployeeModel,
  ): void {
    let minDate = '';

    if (this.minimumDueDateAutoPreFillSetting) {
      const minDateFromParentResolution = this.getMinDateFromParentResolution(this.getMissionFromParentResolution());
      minDate = this.getSubtractedDate(minDateFromParentResolution);
    }

    const commissions = question.get('commissions') as FormArray;
    const commissionGroup = commissions.at(commissionIdx) as FormGroup;

    this.addPerformer(commissionGroup, employee, minDate);

    if (questionIdx === 0 && commissionIdx === 0) {
      // если исполнитель добавлен в первый вопрос
      // Вызываем модальное окно добавления пользователей во все поручения
      this.showSpreadExecutorModal(employee, minDate);
    }
  }

  /** индекс соответствующей родительской резолюции */
  public parentMissionData(commissionForm: AbstractControl): ParentMissionData {
    return commissionForm?.get(PARENT_COMMISSION_DATA)?.value?.parentMissionData as ParentMissionData;
  }

  public parentMissionIndexExist(commissionForm: AbstractControl): boolean {
    const parentMissionData = this.parentMissionData(commissionForm);

    return !!parentMissionData;
  }

  /**
   * Копирует текст из предыдущего поручения в текущее
   *
   * @param question контрол текущего вопроса
   * @param commissionIdx индекс текущего поручения
   */
  public copyPreviousCommission(question: AbstractControl, commissionIdx: number): void {
    const currentCommission = this.getTextControlFromCommissionFormGroup(question, commissionIdx);
    const previousCommission = this.getTextControlFromCommissionFormGroup(question, commissionIdx - 1);

    if (previousCommission) {
      currentCommission.setValue(previousCommission.value);
    }
  }

  /** Сохранить текст поручения в отдельный контрол формы, чтобы загрузить его когда удалили изображение */
  public saveCommissionText(value: string, commission: AbstractControl): void {
    const commissionForm = commission as FormGroup;
    this.graphicHelperService.saveCommissionText(value, commissionForm);
  }

  /** Удаляет соответствующий индекс родительской резолюции из формы */
  private removeParentCommissionIndex(commissionForm: AbstractControl): void {
    (commissionForm as FormGroup).removeControl(PARENT_COMMISSION_DATA);
  }

  /** Возвращает контрол с текстом поручения из формы поручения по индексу */
  private getTextControlFromCommissionFormGroup(question: AbstractControl, commissionIdx: number): AbstractControl {
    const commissionFormGroup = this.getCommissionArrayByQuestion(question).controls[commissionIdx] as FormGroup;

    return commissionFormGroup.controls['text'];
  }

  /**
   * Рассчитывает и вставляет данные в форму
   *
   * @param questionForm форма вопроса в которой находится поручение
   * @param commissionForm форма поручения в которую необходимо вставить данные
   * @param parentMissionData данные из родительской резолюции
   * @param replaceData стоит ли заменять данные формы безусловно
   * @param supplementParentResolutionText стоил ли "дополнять" текст поручения данными из родительского. Дополнение означает, что текст родительского поручения будет добавлен в конец
   */
  private patchDataToForm(
    questionForm: AbstractControl,
    commissionForm: AbstractControl,
    parentMissionData: ParentMissionData,
    replaceData: boolean = false,
    supplementParentResolutionText: boolean = false,
  ): void {
    if (replaceData || !commissionForm.value.text) {
      commissionForm.patchValue({
        text: parentMissionData.parentMissionText,
        savedText: parentMissionData.parentMissionText,
      });
    }

    if (supplementParentResolutionText) {
      // Обрезаем пробелы справа у текущего текста поручения
      const trimCurrentComissionText = commissionForm.get('text').value.replace(/~*$/, '');
      // соединяем строки родительского поручения и текущего через пробел
      const supplementResolutionText = `${trimCurrentComissionText} ${parentMissionData.parentMissionText}`;

      commissionForm.patchValue({
        text: supplementResolutionText,
        savedText: supplementResolutionText,
      });
    }

    if (parentMissionData.parentMissionDate.needsFilling) {
      const firstPerformerFormGroup = this.getFirstPerformerFormGroupFromExistingCommissionForms(
        this.getCommissionArrayByQuestion(questionForm),
        parentMissionData.parentMissionIndex,
      );

      if (replaceData || !firstPerformerFormGroup.value.date) {
        firstPerformerFormGroup.patchValue({
          date: parentMissionData.parentMissionDate.value,
        });
      }
    }

    if (parentMissionData.parentMissionControl.needsFilling) {
      const firstPerformerFormGroup = this.getFirstPerformerFormGroupFromExistingCommissionForms(
        this.getCommissionArrayByQuestion(questionForm),
        parentMissionData.parentMissionIndex,
      );

      if (replaceData || !firstPerformerFormGroup.value.control) {
        firstPerformerFormGroup.patchValue({
          control: parentMissionData.parentMissionControl.value,
        });
      }
    }
  }

  // Возвращает перую форму исполнителя из формы поручения
  private getFirstPerformerFormGroupFromExistingCommissionForms(
    commissionsForms: FormArray,
    parentMissionIndex: number,
  ): FormGroup {
    const performerFormArray = this.getPerformerFormArray(commissionsForms, parentMissionIndex);

    return performerFormArray.controls[0] as FormGroup;
  }

  private getSubtractedDate(date: Date): string {
    if (!date) {
      return '';
    }

    if (date) {
      // Вычитаем из даты рабочие дни
      const reduceExecutionPeriod = this.store.selectSnapshot(SettingsState.reduceExecutionPeriod);
      this.dateHelper.subtractWorkdays(date, reduceExecutionPeriod);
    }

    if (this.dateHelper.subtractDateFromToday(date) < 0) {
      return new Date().toISOString();
    }

    return date.toISOString();
  }

  /** Возвращает исполнителей где текущий пользователь указан исполнителем */
  private getCurrentUserAddressesFromParentMissions(
    currentUser: EmployeeModel,
    parentMissions: MissionModel[],
  ): ResolutionAddresseeModel[] {
    // Функция, которая возвращает true, если текущий пользователь является исполнителем
    const addresseeIsCurrentEmployee = (addressee: ResolutionAddresseeModel): boolean =>
      addressee.employee?.id === currentUser.id;

    // все родительские поручения, где текущий пользователь указан как исполнитель
    const allParentMissionsWhereCurrentEmployeeExist = parentMissions?.filter((mission) =>
      mission.addressee.some(addresseeIsCurrentEmployee),
    );

    // все сущности "Исполнитель" в которых указан текущий пользователь
    return flatMap(allParentMissionsWhereCurrentEmployeeExist, (mission) => mission.addressee).filter(
      addresseeIsCurrentEmployee,
    );
  }

  private getMinDateFromParentResolution(parentMissions: MissionModel[]): Date {
    // Если копирование минимальной даты включено, но копирование всех поручений - отключено, то находим минимальную дату из тех поручений, где текущий пользователь указан как исполнитель
    const allCurrentEmployeeAddressee = this.getCurrentUserAddressesFromParentMissions(
      this.currentUser.getCurrentUserSnapshot(),
      parentMissions,
    );

    return this.getMinDateFromAddresses(allCurrentEmployeeAddressee);
  }

  private getMissionFromParentResolution(): MissionModel[] {
    const resolution = this.store.selectSnapshot(ResolutionState.getResolutionProjectCard);

    // Возвращает текущего пользователя. При работе от ИО возвращет ИО
    const currentUser = this.currentUser.getCurrentUserSnapshot();

    // Отдаем только те поручения, где пользователь указан исполнителем
    return resolution.missions.filter((mission) => this.userIsAddresseeInMission(currentUser, mission));
  }

  /** Определяет является ли пользователь исполнителем в поручении */
  private userIsAddresseeInMission(user: EmployeeModel, mission: MissionModel): boolean {
    return mission.addressee.some((a) => a?.employee?.id === user.id);
  }

  /** Возвращает минимальную дату из переданных поручений */
  private getMinDateFromAddresses(allCurrentEmployeeAddressee: ResolutionAddresseeModel[]): Date {
    const dueDates = allCurrentEmployeeAddressee
      .map((addressee) => addressee.dueDate)
      .filter((dueDate) => dueDate)
      .map((dueDate) => new Date(dueDate));

    // Сортируем даты исполнения по возрастанию
    const sortedDueDates = dueDates.sort((a, b) => a.getTime() - b.getTime());

    // Берем самую маленькую дату (самую ближнюю)
    return sortedDueDates[0];
  }

  /** Инициализация формы */
  private initForm(data: FormResolutionModel): void {
    this.form = this.fb.group({
      author: [data?.author, Validators.required],
      onBehalfOf: [data?.onBehalfOf, Validators.required],
      questions: this.fb.array(this.createQuestionsArray()),
    });
  }
}
