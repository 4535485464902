import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import * as Sentry from '@sentry/angular';
import { SharedModule } from '@npaShared/shared.module';
import { SharedApplicationModule } from '@shared/shared-application.module';
import { SharedOogModule } from '@oogShared/shared-oog.module';
import { CoreModule } from '@npaCore/core.module';
import { StoreList } from '@store/store-list';
import { GlobalErrorHandler } from '@shared/handlers/global-error-handler';
import { InternetInterceptor } from '@shared/interceptors/internet.interceptor';
import { HeadersInterceptor } from '@shared/interceptors/headers.interceptor';
import { HealthTimeoutInterceptor } from '@shared/interceptors/health-timeout.interceptor';
import { EmployeeReplacedState } from '@store/main-screen/employee-replaced/employee-replaced.state';
import { ErrorCheckInterceptor } from '@shared/interceptors/error-check.interceptor';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { BookmarksModule } from './oog/bookmarks/bookmarks.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    CoreModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    SharedOogModule,
    SharedApplicationModule,
    CommonModule,
    BookmarksModule,
    NgxsModule.forRoot(StoreList, { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [EmployeeReplacedState],
      storage: StorageOption.LocalStorage,
    }),
    IonicStorageModule.forRoot({
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    InAppBrowser,
    ScreenOrientation,
    Network,
    BackgroundMode,
    File,
    DocumentViewer,
    SocialSharing,
    Device,
    IOSFilePicker,
    Badge,
    AndroidPermissions,
    Media,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternetInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HealthTimeoutInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCheckInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
