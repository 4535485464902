import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { DeviceTypeEnum } from '@enums/device-type.enum';
import { NavController } from '@ionic/angular';
import { BackendBuildInfoResponse } from '@models/settings/backend-build-info.model';
import { BackendBuildInfoService } from '@shared/services/backend-build-info.service';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { environment } from '../../../environments/environment';
import { routeNames } from '../../app-routing/route-names.const';

@Injectable({
  providedIn: 'root',
})
export class AppVersionGuard implements CanActivate {
  public skipUpdate = false;

  constructor(
    private navController: NavController,
    private backendBuildInfoService: BackendBuildInfoService,
    private deviceInfoService: DeviceInfoService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route.queryParams?.skipUpdate) {
      this.skipUpdate = route.queryParams.skipUpdate;
    }

    if (this.skipUpdate) {
      return of(true);
    }

    return this.backendBuildInfoService.getOog().pipe(
      catchError((error) => {
        console.warn(error);

        const passResponse: BackendBuildInfoResponse = {
          applicationVersion: '',
          branchName: '',
          commitHash: '',
          isCritical: false,
          mobileVersion: `${environment.mobileVersion}`,
          service: '',
        };

        return of(passResponse);
      }),
      switchMap((res: BackendBuildInfoResponse) => {
        const deviceType = this.deviceInfoService.getDeviceType();
        if (deviceType === DeviceTypeEnum.IOS && res?.mobileVersion !== environment.mobileVersion) {
          this.navController.navigateRoot(routeNames.appUpdate, { queryParams: { isCritical: res.isCritical } }).then();
          return of(false);
        }
        return of(true);
      }),
      first(),
    );
  }
}
