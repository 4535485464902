import { Injectable } from '@angular/core';

type DataKey = 'oogData' | 'aissdData';
@Injectable({
  providedIn: 'root',
})
export class LoginPassStorageService {
  public setIsProUsername(username: string): void {
    this.setItem('isProUsername', username);
  }

  public setAisSdUsername(username: string): void {
    this.setItem('aisSdUsername', username);
  }

  public setIsProLogin(login: string): void {
    this.updateData('oogData', { login });
  }

  public setAisSdLogin(login: string): void {
    this.updateData('aissdData', { login });
  }

  public setIsProPassword(password: string): void {
    this.updateData('oogData', { password });
  }

  public setAisSdPassword(password: string): void {
    this.updateData('aissdData', { password });
  }

  public getIsProUsername(): string {
    return this.getItem('isProUsername');
  }

  public getAisSdUsername(): string {
    return this.getItem('aisSdUsername');
  }

  public getIsProLogin(): string {
    return this.getData('oogData')?.login;
  }

  public getAisSdLogin(): string {
    return this.getData('aissdData')?.login;
  }

  public getIsProPassword(): string {
    return this.getData('oogData')?.password ?? null;
  }

  public getAisSdPassword(): string {
    return this.getData('aissdData')?.password ?? null;
  }

  public removeIsProUsername(): void {
    localStorage.removeItem('isProUsername');
  }

  public removeAisSdUsername(): void {
    localStorage.removeItem('aisSdUsername');
  }

  private setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  private getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  private setData(key: DataKey, data: any): void {
    this.setItem(key, btoa(unescape(encodeURIComponent(JSON.stringify(data)))));
  }

  private getData(key: DataKey): any {
    if (this.getItem(key)) {
      return JSON.parse(atob(this.getItem(key)));
    }

    return null;
  }

  public updateData(key: DataKey, data: any): void {
    this.setData(key, {
      ...this.getData(key),
      ...data,
    });
  }
}
