import envInfo from '../../package.json';

const host = 'https://mob-ispro.mos.ru';

export const environment = {
  production: false,
  mobileVersion: envInfo?.version,
  envName: 'preprod',
  host,
  oogUrl: `${host}/oog-mobile`,
  oogOibUrl: `${host}`,
  npaEnabled: true,
  baseUrl: `${host}/mo/npa/api`,
  mobileUrl: `${host}/mo/npa/api/mobile`,
  securityMobileUrl: `${host}/mo/npa/mobile`,
  npaWebsocketBaseUrl: `${host}/npa/socket`,
  ldeUrl: 'lde-api/api/v1/npa',
  isproUrl: 'https://ispro-test.mos.ru',
  aissdUrl: 'https://usd-preprod.mos.ru',
  enableSentry: false,
  sentryDsn: 'https://4dadf980ac904f02b78af931efc9ecf4@sentry.it2g.ru/3',
  appStoreUrl: 'itms-apps://apps.apple.com/ru/app/%D0%BC%D0%BE%D1%81%D0%BE%D1%84%D0%B8%D1%81/id1546857767?l=ru',
  updateDelay: 300000,
};
