import { Pipe, PipeTransform } from '@angular/core';

import { ApprovalParticipantStatusNamesEnum } from '../../../enums/approval-list/approval-participant-status-names.enum';
import { RedirectBlocksEmployeesTypeEnum } from '../../../enums/redirect-blocks-employees-type/redirect-blocks-employees-type.enum';
import { ApprovalRedirectWithCommentModel } from '../../../models/approval-classes/approval-redirect-with-comment.model';
import { ApprovalParticipantModel } from '../../../models/approval-list/approval-participant.model';

@Pipe({
  name: 'approvalRedirectWithComment',
})
export class ApprovalRedirectWithCommentPipe implements PipeTransform {
  public transform(participant: ApprovalParticipantModel, isBorder: boolean): ApprovalRedirectWithCommentModel {
    const approvalStatus = ApprovalParticipantStatusNamesEnum;

    return {
      'border-approved': participant?.status === approvalStatus.agree || participant?.status === approvalStatus.signed,
      'border-in-progress':
        participant?.status === approvalStatus.onAgreement || participant?.status === approvalStatus.redirect,
      'border-returned':
        !participant?.status ||
        participant?.status === approvalStatus.notAgree ||
        participant?.status === approvalStatus.comebackToInitiator,
      'border-inactive': !participant?.status,
      'no-border': !isBorder,
    };
  }
}
