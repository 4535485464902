import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { race, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { Store } from '@ngxs/store';
import { SidebarState } from '@store/menu/sidebar/sidebar.state';

import { KeyboardKeysEnum } from '@enums/keyboard-keys.enum';
import { BaseControl } from '../control-base';
import { UserCardService } from '@shared/services/user-card.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent extends BaseControl implements OnInit, OnDestroy {
  /** флаг неообходимости писать с заглавной */
  @Input() public isNeedTitleCase = false;

  /** флаг неообходимости писать все с заглавной */
  @Input() public isNeedUpperCase = false;

  /** показать иконку "внимание" */
  @Input() public attention = false;

  /** задержка на отправку автоматического запроса */
  @Input() public delayOnAutoSubmit = 0;

  @Input() public isDisabled = false;

  @Input()
  public searchBox = new FormControl();

  /** отправить запрос на поиск */
  @Output() public submitEvent: EventEmitter<string> = new EventEmitter();

  @ViewChild('search') public search: ElementRef;

  private unsubscribe$: Subject<void> = new Subject<void>();

  private onBlur$: Subject<void> = new Subject<void>();

  private onClear$: Subject<void> = new Subject<void>();

  constructor(private store: Store, private userCardService: UserCardService) {
    super();
  }

  /** показать иконку "крестик" - очистить поле */
  public get isClear(): boolean {
    return Boolean(this.searchBox.value);
  }

  @HostListener('document:keypress', ['$event'])
  public handleEnterPress(event: KeyboardEvent): void {
    if (event.key === KeyboardKeysEnum.Enter) {
      this.search.nativeElement.blur();
    }
  }

  public ngOnInit(): void {
    this.enableAutoSubmit();

    this.store
      .select(SidebarState.searchValue)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (this.searchBox.value !== value && !this.isDisabled) {
          if (!!value) {
            this.searchBox.patchValue(value);
            this.submitForm();
          } else {
            this.clearForm();
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.onBlur$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** убрали фокус с контрола */
  public onBlurAction(): void {
    this.trimSearchControlValue();
    this.onBlur$.next();
  }

  public submitForm(): void {
    this.submitEvent.emit(this.searchBox.value);
  }

  public clearForm(): void {
    this.searchBox.setValue('');
    this.onClear$.next();
    this.userCardService.destroyCard();
  }

  /** автосабмит поиска с задержкой  */
  public enableAutoSubmit(): void {
    this.searchBox.valueChanges
      .pipe(
        debounce(() => race([this.onClear$, this.onBlur$, timer(this.delayOnAutoSubmit)])),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.submitForm();
      });
  }

  /** убрать крайние пробелы из значения контрола */
  private trimSearchControlValue(): void {
    if (this.searchBox.value) {
      this.searchBox.setValue(this.searchBox.value.trim());
    }
  }
}
