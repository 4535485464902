import { Injectable } from '@angular/core';
import { DossierResponseModel } from '@oogShared/models/dossier/dossier-response.model';
import { DossierState } from '@store/resolution-store/dossier/dossier.state';
import { filter, first } from 'rxjs/operators';

import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { ResetForm } from '@store/forms/forms.action';
import { FormsState } from '@store/forms/forms.state';
import { ActiveCardState } from '@store/menu/active-card/active-card.state';
import { ResetDossier } from '@store/resolution-store/dossier/dossier.action';
import { ResetResolution } from '@store/resolution-store/resolution/resolution.action';
import { ResolutionState } from '@store/resolution-store/resolution/resolution.state';

import { DraftsInteropService } from '@oogShared/rest/drafts-interop.service';
import { ResolutionTypeEnum } from '@oogShared/enums/resolution-type/resolution-type.enum';
import { modalIdRedirectResolution } from '@const/modals-id.const';
import { UserInfoApiService } from '@npaApi/user-info.api.service';
import { SetStatusCurrentUserAssistant } from '@store/status-current-user-assistant/status-current-user-assistant.action';
import { performerType } from '../../consts/performer-type.const';
import { reviewerType } from '../../consts/reviewer-type.const';
import { FormHeaderEnum } from '../../enums/resolution-form/form-header.enum';
import { returnAuthUserSession } from '../../functions/return-user-session.function';
import { CreateChildResolutionModel } from '../../models/create-resolution/create-child-resolution.model';
import { CreateResolutionModel } from '../../models/create-resolution/create-resolution.model';
import { FormHeaderModel } from '../../models/forms-input/form-header.model';
import { AddresseeFormModel } from '../../models/forms/addressee-form.model';
import { ResolutionRedirectModel } from '../../models/forms/forms.model';
import { EmployeeModel } from '../../models/resolutions/employee/employee.model';
import { MissionModel } from '../../models/resolutions/mission/mission.model';
import { ResolutionAddresseeModel } from '../../models/resolutions/resolution/resolution-addressee.model';
import { ResolutionTypeModel } from '../../models/resolutions/resolution/resolution-type.model';
import { ResolutionModel } from '../../models/resolutions/resolution/resolution.model';
import { ResolutionInteropService } from '../../rest/resolution-interop.service';

import { DocumentNavigationService } from '../document-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class FormRedirectResolutionService {
  constructor(
    private store: Store,
    private resolutionInterop: ResolutionInteropService,
    private currentUser: CurrentUserIsproStoreService,
    private modalCtrl: ModalController,
    private authUser: AuthUserIsproStoreService,
    private draftsInteropService: DraftsInteropService,
    private documentNavigationService: DocumentNavigationService,
    private userInfoService: UserInfoApiService,
  ) {}

  /** Смапить данные в модель шапки формы */
  public mapRedirectFormHeader(): FormHeaderModel {
    const date = this.store.selectSnapshot(ActiveCardState.dateResolution);
    const number = this.store.selectSnapshot(ActiveCardState.numberResolution);

    return {
      title: FormHeaderEnum.redirect,
      numberCardDocument: number,
      date,
    };
  }

  /** Перенаправить проект резолюции */
  public redirectResolution(): void {
    const resolution = this.createResolution();
    const card = this.store.selectSnapshot(ActiveCardState.getActiveCard);
    this.resolutionInterop
      .createResolution(resolution, card)
      .pipe(
        first(),
        filter((r) => !!r),
      )
      .subscribe(() => {
        returnAuthUserSession(this.store, this.currentUser, this.authUser);
        this.documentNavigationService.nextDocument();
        this.store.dispatch([ResetForm, ResetResolution, ResetDossier]);
        this.modalCtrl.dismiss(undefined, undefined, modalIdRedirectResolution).then();
      });
  }

  /** Проверить наличие помощника */
  public checkHasAssistant(): void {
    this.userInfoService
      .checkHasAssistant()
      .pipe(first())
      .subscribe((value) => this.store.dispatch(new SetStatusCurrentUserAssistant(value)));
  }

  /** Смаппить данные в модель бэка при создании резолюции */
  private createResolution(): CreateResolutionModel {
    const resolution = JSON.parse(JSON.stringify(this.store.selectSnapshot(ResolutionState.getResolutionProjectCard)));
    const form = this.store.selectSnapshot(FormsState.getFormRedirectResolution);

    const addressee = form.addressee as EmployeeModel;
    return {
      author: this.currentUser.getCurrentUserSnapshot(),
      childResolutions: resolution.childResolutions ? this.childResolutionRedirect(resolution) : null,
      creationDate: new Date(),
      files: resolution.files,
      isDraft: resolution.isDraft,
      missions: [this.missionRedirect(form, resolution)],
      number: resolution.number,
      options: {},
      parentResolutionId: resolution.id,
      type: this.resolutionType(resolution.type),
      employeeDepartmentIdSendOnAcceleratedReview: form.expeditedReview ? addressee.department?.id : null,
      isOnAcceleratedReview: form.expeditedReview || false,
      fileResolutionId: form.fileId || null,
      resolutionKind: form.resolutionKind || null,
    };
  }

  private resolutionType(type: ResolutionTypeModel): ResolutionTypeModel {
    switch (type.id) {
      case ResolutionTypeEnum.resolutionProject:
        return type;
      case ResolutionTypeEnum.requestProject:
        return type;
      default:
        return {
          id: ResolutionTypeEnum.heading,
          name: 'Направление',
        };
    }
  }

  private childResolutionRedirect(resolution: ResolutionModel): CreateChildResolutionModel[] {
    return resolution.childResolutions?.map((r) => ({
      author: r.author,
      childResolutions: r.childResolutions,
      files: r.files,
      fromEmployee: r.fromEmployee,
      missions: [...r.missions.map((m: MissionModel, index: number) => this.cloneMissions(m, index))],
      type: r.type,
    }));
  }

  private missionRedirect(form: ResolutionRedirectModel, resolution: ResolutionModel): MissionModel {
    const dossiers = this.store.selectSnapshot(DossierState.getDossier);
    const copyDossiers = JSON.parse(JSON.stringify(dossiers)) as DossierResponseModel;
    let newDossier = null;
    if (copyDossiers) {
      newDossier = {
        id: null,
        comment: copyDossiers.comment,
        dossierAppealMovementLinks: copyDossiers.dossierLinks,
      };
    }
    const isCitizenAppeal = this.store.selectSnapshot(ActiveCardState.getActiveCard).isCitizenAppeal;
    const executorSign = form.executorSign;
    const mission = {
      addressee: Array.isArray(form.addressee)
        ? form.addressee.map((p: AddresseeFormModel, j: number) => this.addresseeRedirect(p, j))
        : [...this.addresseeRedirectProject(form, 0), ...this.reviewersRedirectProject(form)],
      dossiers: newDossier ? [newDossier] : [],
      forPrepareAnswer: true,
      hasProjectLetter: form.hasProjectLetter || false,
      isCommentConfidential: form.privateComment,
      isWithoutExecutorOrAddressee: executorSign || null,
      needAttention: form.needAttention,
      missionAction: null,
      privateComment: form.fileId ? '' : form.text,
      projectLetterAuthor: form.projectLetterAuthor || null,
      serialNumber: 1,
      ...(isCitizenAppeal && { questions: resolution.missions[0]?.questions }),
    };
    if (form.executorSign) {
      mission.addressee.push({
        addresseeType: {
          id: 1,
          name: 'Исполнитель',
        },
        checkDateType: null,
        decontrolDate: null,
        dueDate: null,
        employee: null,
        forPrepareAnswer: null,
        initialDueDate: null,
        isControl: false,
        isResponsibleByMission: false,
        missionAction: null,
        reportDate: null,
        serialNumber: mission.addressee.length + 1,
      });
    }

    return mission;
  }

  private addresseeRedirect(form: AddresseeFormModel, index: number): ResolutionAddresseeModel {
    const addresseeType = form.familiarization ? reviewerType : performerType;
    return {
      addresseeType,
      employee: form.addressee,
      isControl: false,
      personalNote: form.notes || null,
      serialNumber: index + 1,
      urgencyReviewType: form.urgency || null,
    };
  }

  private addresseeRedirectProject(form: ResolutionRedirectModel, index: number): ResolutionAddresseeModel[] {
    if (!form.addressee) {
      return [];
    }

    return [
      {
        addresseeType: performerType,
        employee: form.addressee as EmployeeModel,
        isControl: false,
        personalNote: null,
        serialNumber: index + 1,
        urgencyReviewType: form.urgency || null,
      },
    ];
  }

  private reviewersRedirectProject(form: ResolutionRedirectModel): ResolutionAddresseeModel[] {
    if (!form.reviewers) {
      return [];
    }

    return form.reviewers.map((reviewer, index) => ({
      addresseeType: reviewerType,
      employee: reviewer.performer as EmployeeModel,
      isControl: false,
      personalNote: null,
      serialNumber: index + 1,
      urgencyReviewType: reviewer.urgency || null,
    }));
  }

  private cloneMissions(mission: MissionModel, index: number): MissionModel {
    return {
      addressee: this.removeAddresseeId(mission),
      curator: mission.curator,
      dossiers: mission.dossiers,
      forPrepareAnswer: mission.forPrepareAnswer,
      isCommentConfidential: mission.isCommentConfidential,
      isWithoutExecutorOrAddressee: mission.isWithoutExecutorOrAddressee,
      missionAction: mission.missionAction,
      missionAnswer: mission.missionAnswer,
      privateComment: mission.privateComment,
      serialNumber: index + 1,
      type: mission.type,
      questions: mission.questions,
      files: mission.files,
    };
  }

  private removeAddresseeId(mission: MissionModel): ResolutionAddresseeModel[] {
    return mission.addressee.map((a) => ({
      ...a,
      id: null,
    }));
  }
}
