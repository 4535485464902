import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { NotificationsBarDirective } from '@shared/directives/notifications-bar.directive';
import { HideHeaderDirective } from '@shared/directives/hide-header.directive';
import { ScrollCatcherDirective } from './scroll-cather.directive';
import { UserCardDirective } from './user-card.directive';

export const Directives = [
  ScrollCatcherDirective,
  UserCardDirective,
  ClickOutsideDirective,
  NotificationsBarDirective,
  HideHeaderDirective,
];
