import { Injectable } from '@angular/core';

import { ModalConnectionComponent } from '@components/modal-connection/modal-connection.component';
import { ModalErrorComponent } from '@components/modal-error/modal-error.component';
import { TemplateModalWithIconAlarmComponent } from '@components/template-modal-with-icon-alarm/template-modal-with-icon-alarm.component';
import { TemplateModalComponent } from '@components/template-modal/template-modal.component';
import { modalIdNetwork } from '@const/modals-id.const';
import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { ProjectEnum } from '@enums/project.enum';
import { ModalController } from '@ionic/angular';
import { CertificatesSettingsModel } from '@models/settings/certificates-settings.model';
import { breadcrumbSentry, logToSentry } from '@shared/functions/set-users-for-sentry.function';
import { SettingsCertificateService } from './certificates/settings-certificate.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public modalConnectionExists = false;

  constructor(private modalController: ModalController, private settingsService: SettingsCertificateService) {}

  public async presentModalError(text?: string, refresh?: boolean): Promise<void | boolean> {
    const modal = await this.modalController.create({
      component: ModalErrorComponent,
      showBackdrop: true,
      backdropDismiss: false,
      swipeToClose: false,
      keyboardClose: false,
      cssClass: 'error-modal',
      componentProps: {
        label: text,
        refresh,
        title: refresh ? 'Внимание!' : 'Системная ошибка',
      },
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res.data?.refresh) {
      return res.data?.refresh;
    }
  }

  public async showModalConnection(): Promise<HTMLIonModalElement> {
    if (this.modalConnectionExists) {
      return;
    }

    const modal = await this.modalController.create({
      component: ModalConnectionComponent,
      cssClass: 'transparent-background modal-connection',
      id: modalIdNetwork,
    });

    this.modalConnectionExists = true;

    await modal.present();

    return modal;
  }

  public async checkOpenConnectionModel(): Promise<void> {
    if (this.modalConnectionExists) {
      await this.modalController.dismiss(null, '', modalIdNetwork);
    }
  }

  /** Показать обычную маленькую модалку. Передать заголовок, основной текст, текст и цвет кнопки */
  public async presentTemplateModal(title: string, text: string, buttonLabel: string): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component: TemplateModalComponent,
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: 'default-small-modal',
      componentProps: {
        title,
        text,
        buttonLabel,
      },
    });

    const settings = {
      oog: this.settingsService.getSettings(ProjectEnum.oog).getAllSettings(),
      npa: this.settingsService.getSettings(ProjectEnum.npa).getAllSettings(),
    } as CertificatesSettingsModel;

    logToSentry('Текущие настройки сертификатов из стораджа: ', { settings });

    modal.present();

    return modal;
  }

  /** Показать обычную маленькую модалку с красной иконкой alarm. Передать заголовок, основной текст, текст и цвет кнопки */
  public async presentModalWithAlarmIcon(
    title: string,
    text: string,
    buttonLabel: string,
  ): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component: TemplateModalWithIconAlarmComponent,
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: 'certificate-valid-error',
      componentProps: {
        title,
        text,
        buttonLabel,
      },
    });

    modal.present();

    return modal;
  }

  public async showAnyModal(
    component: any,
    cssClass: string,
    animated: boolean,
    componentProps?: any,
  ): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component,
      backdropDismiss: false,
      cssClass,
      animated,
      componentProps,
    });

    modal.present();
    return modal;
  }
}
