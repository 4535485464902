import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { NotificationDataModel } from '@models/notifications/notifications-data.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Storage } from '@ionic/storage-angular';
import { NotificationService } from '@oogShared/services/notification.service';
import { logToSentry, setUsersForSentry } from '@shared/functions/set-users-for-sentry.function';
import { BackgroundHelperService } from '@shared/services/background-helper.service';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { FCMNotificationService } from '@shared/services/fcm-notification.service';
import { NetworkService } from '@shared/services/network.service';
import { NotificationToastService } from '@shared/services/notification-toast.service';
import { NotificationsState } from '@store/notifications/notifications.state';
import { VisibilityBackdropState } from '@store/visibility-backdrop/visibility-backdrop.state';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { BadgeService } from '@shared/services/badge.service';
import { StartTimerCache } from '@store/cache-timing/cache-timing.action';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { PermissionPushNotificationService } from '@shared/services/permissions/permission-push-notification.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { FCMNotificationTypeEnum } from '@enums/fcm-notification-type.enum';
import { AddNotification } from '@store/notifications/notifications.action';
import { routeNames } from './app-routing/route-names.const';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public showBackdrop = false;
  public loader = false;

  public destroy: Observable<void>;

  constructor(
    private authUserIsProService: AuthUserIsproStoreService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private backgroundMode: BackgroundMode,
    private fcmNotificationService: FCMNotificationService,
    private notificationToastService: NotificationToastService,
    private store: Store,
    private loadingController: LoadingController,
    private backgroundHelper: BackgroundHelperService,
    private navController: NavController,
    private modalController: ModalController,
    private networkService: NetworkService,
    private meta: Meta,
    private badgeService: BadgeService,
    private deviceInfoService: DeviceInfoService,
    private notificationService: NotificationService,
    private storage: Storage,
    private router: Router,
    private permissionPushNotification: PermissionPushNotificationService,
  ) {
    this.initializeApp();
  }

  public ngOnDestroy(): void {}

  public ngOnInit(): void {
    this.routerEvents();
    this.loadingController.create();
    this.modalController.create(null);
    this.networkService.startIntervalForCheckConnectionOogServer();
    this.networkService.startIntervalForCheckConnectionNpaServer();
  }

  private async initializeApp(): Promise<void> {
    this.store.select(VisibilityBackdropState.isVisibleBackdrop).subscribe((r) => (this.showBackdrop = r));

    await this.storage.create();
    this.store.dispatch(StartTimerCache);

    await this.platform.ready();
    if (this.platform.is('capacitor') && !this.platform.is('electron')) {
      this.changeViewportForAndroid();

      this.initStatusBar();

      this.splashScreen.hide();
      this.backgroundMode.enable();

      this.fcmNotificationService.fcmSubscribe();
      this.subscribeOnNotification();

      this.initializeAndSubscribeOnBackground();
      this.permissionPushNotification.requestPermission();
    }
    const uuid = this.deviceInfoService.getDeviceGuid();
    setUsersForSentry(uuid);
    this.autoSendPhoneInfo();
    (window as any).showDesktopNotifications = this.showDesktopNotifications.bind(this);
  }

  private routerEvents(): void {
    this.router.events?.pipe(untilDestroyed(this)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.loader = true;
      }
      if (event instanceof NavigationEnd) {
        this.loader = false;
      }
    });
  }

  /** Метод добавлен в глобальный объект window, чтобы можно было вызывать пуш уведомление через консоль */
  private showDesktopNotifications(): void {
    const notifications = this.store.selectSnapshot(NotificationsState.getNotifications);
    if (!notifications?.length) {
      return;
    }

    const notification = new Notification(notifications[0].mobileNoticeType.noticeType.name, {
      body: notifications[0].body,
    });

    if (notification) {
      notification.onclick = (e: Event): void => {
        e.preventDefault();
        this.openDocumentCard(notifications[0]);
      };
    }
  }

  private openDocumentCard(notification: NotificationDataModel): void {
    const { appealMovementNumber, mainId, mobileNoticeType } = notification;
    this.notificationService.openDocumentCard(appealMovementNumber, mainId, null, mobileNoticeType?.id).then();
  }

  private changeViewportForAndroid(): void {
    const deviceWidth = window.innerWidth;
    const deviceHeight = window.innerHeight;
    if (deviceWidth < 1024 && this.platform.is('android')) {
      const widthCoefficient = deviceWidth / 1024;
      const heightCoefficient = deviceHeight / 768;
      const scaleCoefficient = widthCoefficient < heightCoefficient ? widthCoefficient : heightCoefficient;
      const scaleCoefficientFixed = scaleCoefficient.toFixed(1);
      this.meta.updateTag({ name: 'viewport', content: `width=1024, initial-scale=${scaleCoefficientFixed}` });
    }
  }

  private initStatusBar(): void {
    StatusBar.setStyle({ style: Style.Dark });
  }

  private subscribeOnNotification(): void {
    this.fcmNotificationService.getFcmEventsFilteredByTypes(FCMNotificationTypeEnum.Notification).subscribe((data) => {
      logToSentry('Установка бейджа при запуске приложения', { data });
      this.badgeService.setBadge(data.badge);
      /** с бэка приходит строка(особенности библиотеки java) */
      const newNotification = JSON.parse(data?.data?.payload);
      this.notificationToastService.show(newNotification);
      this.store.dispatch(new AddNotification(newNotification));
    });
  }

  private initializeAndSubscribeOnBackground(): void {
    this.backgroundHelper.init();
    this.backgroundHelper.canUpdate().subscribe(async () => {
      const modal = await this.modalController.getTop();
      modal?.dismiss();
      this.navController.navigateRoot(routeNames.mainScreen);
    });
  }

  /** Если креды пользователя существуют пересохранить данные девайса */
  private autoSendPhoneInfo(): void {
    this.authUserIsProService
      .getCurrentUser()
      .pipe(
        filter((userIspro) => !!userIspro),
        first(),
      )
      .subscribe(() => this.deviceInfoService.saveDeviceInfo());
  }
}
