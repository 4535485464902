import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as dayjs from 'dayjs';

import { Urls } from '@const/urls';
import { StatisticsEnum } from '@npaShared/enums/statistics-type.enum';
import { DashboardSearchResponseModel } from '@npaShared/models/dashboard-search.response.model';
import { StatisticsResponseModel } from '@npaShared/models/statistics.response.model';

import { FiltersRequest } from '../document-package/models/filter-request.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  constructor(private http: HttpClient) {}

  public getStatistics(value: StatisticsEnum, userId?: number): Observable<StatisticsResponseModel> {
    const url = Urls.npaDashboard.getStatistics;
    let params = new HttpParams();
    params = params.append('name', value);
    if (userId) {
      params = params.append('userId', userId.toString());
    }
    return this.http.get<StatisticsResponseModel>(url, { params });
  }

  /**
   * запрос на получение списка ПД по фильтрам
   *
   * @param filters фильтры, по которым надо отфильтровать
   * @param page
   */
  public getFilters(filters: FiltersRequest, page?: number): Observable<DashboardSearchResponseModel> {
    let params = new HttpParams();

    if (page) {
      params = params.set('page', page.toString());
    }
    if (filters.urgency) {
      params = params.set('urgentType', filters.urgency);
    }
    if (filters.phaseType) {
      params = params.set('phaseType', filters.phaseType);
    }
    if (filters.notRead) {
      params = params.set('notRead', 'true');
    }
    if (filters.redirected) {
      params = params.set('redirected', 'true');
    }
    if (filters.restrictedDocuments) {
      params = params.set('restrictedDocuments', 'true');
    }
    if (filters.text) {
      params = params.set('fulltext', filters.text);
    }
    if (filters.statisticName) {
      params = params.set('statisticsNames', filters.statisticName);
    }
    if (filters.projectGroupTypeId) {
      params = params.set('projectGroupTypeId', filters.projectGroupTypeId);
    }
    if (filters.overdue) {
      params = params.set('overdue', filters.overdue);
    }

    /** Добавление диапазона дат в http-запросе
     * - нет ни одной даты - не добавляем в запрос даты
     * - указаны обе даты - добавляем без изменений
     * - указана только дата "С" (createTaskAfter) - указываем "ДО" = сегодняшний день
     * - указана только дата "ДО" (createTaskBefore) - указываем "С" = '2018-01-01'
     */
    const setDatesToParams = (httpParams: HttpParams): HttpParams => {
      if (filters.createTaskAfter && filters.createTaskBefore) {
        return httpParams
          .set('createTaskAfter', filters.createTaskAfter)
          .set('createTaskBefore', filters.createTaskBefore);
      }
      if (filters.createTaskAfter) {
        const today = dayjs().format('YYYY-MM-DD');
        return httpParams.set('createTaskAfter', filters.createTaskAfter).set('createTaskBefore', today);
      }
      if (filters.createTaskBefore) {
        const dateStart = '2018-01-01';
        return httpParams.set('createTaskAfter', dateStart).set('createTaskBefore', filters.createTaskBefore);
      }
      return httpParams;
    };

    params = setDatesToParams(params);

    const url = Urls.npaDashboard.getFilters;
    return this.http.get<DashboardSearchResponseModel>(url, { params });
  }
}
