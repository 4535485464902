import { AboutEmployeeNpaPipe } from '@shared/pipes/about-employee-npa/about-employee.pipe';
import { AboutEmployeeOogPipe } from '@shared/pipes/about-employee-oog/about-employee-oog.pipe';
import { CastToFormGroupPipe } from '@shared/pipes/cast-to-type/cast-to-type.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe/safe-html.pipe';
import { TimerFormatPipe } from '@shared/pipes/timer-format/timer-format.pipe';
import { TrimPipe } from '@shared/pipes/trim/trim.pipe';

import { CheckEsignResolutionValidPipe } from '@shared/pipes/check-esign-valid/check-esign-resolution-valid.pipe';
import { ShowExtensionPipe } from '@shared/pipes/show-extension/show-extension.pipe';
import { AddCommaPipe } from './add-comma/add-coma.pipe';
import { CountDaysPipe } from './count-days/count-days.pipe';
import { CountDocumentsFolderPipe } from './count-documents-folder/count-documents-folder.pipe';
import { DelegatingsSortPipe } from './delegatings-sort/delegatings-sort.pipe';
import { EmployeeFullNamePipe } from './employee-full-name.pipe';
import { NpaCategoryCountPipe } from './npa-category-count/npa-category-count.pipe';
import { NpaUserShortNamePipe } from './npa-user-short-name.pipe';
import { OogUserShortNamePipe } from './oog-user-short-name/oog-user-short-name.pipe';
import { SafePipe } from './safe/safe.pipe';
import { SearchMatchHighlightPipe } from './search-match/search-match.pipe';
import { SettingsCertificateUserNamePipe } from './settings-certificate-user-name.pipe';
import { DisplayOnlyNumberPipe } from './display-only-number.pipe';
import { ResponsiblePipe } from './responsible/responsible.pipe';
import { CastToFormControlPipe } from './cast-to-form-control/cast-to-form-control.pipe';
import { ApprovalPeriodHeaderPipe } from './approval-period-header.pipe';
import { ShowLocalActsPipe } from './show-local-acts/show-local-acts.pipe';
import { SideMenuPageNamePipe } from './side-menu-page-name/side-menu-page-name.pipe';
import { DisableSubcategoryArrowPipe } from './disable-subcategory-arrow/disable-subcategory-arrow.pipe';
import { GetIsSomeSignerCategoryByParamPipe } from '@shared/pipes/get-signer-category-by-param.pipe';

export const Pipes = [
  SearchMatchHighlightPipe,
  CountDaysPipe,
  CountDocumentsFolderPipe,
  OogUserShortNamePipe,
  NpaUserShortNamePipe,
  NpaCategoryCountPipe,
  DelegatingsSortPipe,
  EmployeeFullNamePipe,
  AddCommaPipe,
  CastToFormGroupPipe,
  CastToFormControlPipe,
  TimerFormatPipe,
  TrimPipe,
  SettingsCertificateUserNamePipe,
  SafePipe,
  SafeHtmlPipe,
  ShowExtensionPipe,
  CheckEsignResolutionValidPipe,
  AboutEmployeeOogPipe,
  AboutEmployeeNpaPipe,
  DisplayOnlyNumberPipe,
  ResponsiblePipe,
  ApprovalPeriodHeaderPipe,
  ShowLocalActsPipe,
  SideMenuPageNamePipe,
  DisableSubcategoryArrowPipe,
  GetIsSomeSignerCategoryByParamPipe,
];
