import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Urls } from '@const/urls';
import { SettingsMenuEnum } from '@enums/settings/settings.enum';
import { BaseApiResponseModel } from '@models/base-api-response.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsInteropService {
  constructor(private http: HttpClient) {}

  public logoutIsPro(deviceGuid: string): Observable<BaseApiResponseModel<null>> {
    const url = Urls.auth.logoutIsPro;
    return this.http.post<BaseApiResponseModel<null>>(url, deviceGuid);
  }

  public logoutAisSd(deviceGuid: string): Observable<void> {
    const url = Urls.auth.logoutAisSd;
    return this.http.post<void>(url, deviceGuid);
  }

  /** сохранения настроек текущего пользователя */
  public saveSettings<T>(category: SettingsMenuEnum, form: T): Subscription {
    const url = Urls.settings[category];

    return this.http
      .post(url, form)
      .pipe(catchError((error) => throwError(error)))
      .subscribe((data) => console.log(data));
  }
}
