import { ButtonsDecisionComponent } from './buttons-decision/buttons-decision.component';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';
import { DocumentBlankComponent } from './document-blank/document-blank.component';
import { DocumentListItemComponent } from './document-list-item/document-list-item.component';
import { HorizontalDocumentNavigationComponent } from './horizontal-document-navigation/horizontal-document-navigation.component';
import { InformationResponsibleExecutorComponent } from './information-responsible-executor/information-responsible-executor.component';
import { RefreshCurrentRouteComponent } from './refresh-current-route/refresh-current-route.component';
import { SharedSmallModalComponent } from './shared-small-modal/shared-small-modal.component';
import { TextareaComponent } from './textarea/textarea.component';

export const Components = [
  DocumentListItemComponent,
  ButtonsDecisionComponent,
  HorizontalDocumentNavigationComponent,
  RefreshCurrentRouteComponent,
  SharedSmallModalComponent,
  ConfirmActionComponent,
  DocumentBlankComponent,
  TextareaComponent,
  InformationResponsibleExecutorComponent,
];

export const EntryComponents = [RefreshCurrentRouteComponent, SharedSmallModalComponent, ConfirmActionComponent];
