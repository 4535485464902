import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationResolutionTypeEnum } from '@oogShared/enums/notification-resolution-type.enum';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { SetActiveDocumentCard } from '@store/menu/active-card/active-card.action';
import { DocumentsCardsState } from '@store/menu/documents-cards/documents-cards.state';
import { ResetDocumentsCategory } from '@store/menu/documents-category/documents-category.action';
import {
  ResetFolderState,
  SetMainIdSearch,
  SetSearch,
  SetShownGroup,
  ResetMainIdSearch,
} from '@store/menu/sidebar/sidebar.action';
import { DocumentCardsInteropService } from '@oogShared/rest/document-cards-interop.service';
import { SetGroupCards, SetGroupCardStatus } from '@store/menu/documents-group/documents-group.action';
import { LoadStatusEnum } from '@enums/load-status.enum';
import { routeNames } from '../../../app-routing/route-names.const';
import { MenuHelperService } from './menu-helper.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store,
    private navController: NavController,
    private modalCtrl: ModalController,
    private popoverController: PopoverController,
    private cardsInterop: DocumentCardsInteropService,
    private menuHelperService: MenuHelperService,
    private router: Router,
    private ngZone: NgZone,
  ) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async openDocumentGroup(mainId: number, groupId: number): Promise<void> {
    this.store.dispatch([new SetShownGroup(true), new SetGroupCardStatus(LoadStatusEnum.loading)]);
    await this.findPathToRedirect();

    // Если список карточек пуст, значит Групповая СПР отработана
    this.cardsInterop
      .getResolutionsGroupCards(groupId)
      .pipe(first())
      .subscribe(
        (response) => {
          const status = !!response.cards?.length ? LoadStatusEnum.success : LoadStatusEnum.error;
          this.store.dispatch([new SetGroupCards(response.cards), new SetGroupCardStatus(status)]);

          if (status === LoadStatusEnum.error) {
            this.menuHelperService.presentErrorGroup();
            return;
          }
          const mainCardInGroup = response.cards.find((card) => card.groupProjects?.appealMovementId === mainId);
          this.store.dispatch(new SetActiveDocumentCard(mainCardInGroup));
        },
        (error) => {
          this.store.dispatch([new SetGroupCardStatus(LoadStatusEnum.error)]);
        },
      );
  }

  public async openDocumentCard(
    appealMovementNumber: string,
    mainId: number,
    groupId?: number,
    noticeTypeId?: number,
  ): Promise<void> {
    this.store.dispatch([
      new SetSearch(appealMovementNumber),
      new SetMainIdSearch(mainId),
      ResetDocumentsCategory,
      ResetFolderState,
    ]);
    if (noticeTypeId === NotificationResolutionTypeEnum.expeditedReview) {
      await this.ngZone.run(() => {
        this.navController.navigateForward(routeNames.expeditedReview);
      });
      return;
    }
    await this.findPathToRedirect();
    this.unsubscribe$.next();

    this.store
      .select(DocumentsCardsState.getCards)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cards) => {
        const groupCard = cards?.find(
          (card) =>
            card.groupProjects?.appealMovementId === mainId && card.groupProjects?.groupProjects?.id === groupId,
        );
        if (groupCard) {
          this.store.dispatch(new SetActiveDocumentCard(groupCard));
          return;
        }
        const targetCard = cards.find((card) => card.mainId === mainId);
        if (targetCard) {
          this.store.dispatch(new SetActiveDocumentCard(targetCard));
          return;
        }
      });
    (await this.modalCtrl.getTop())?.dismiss().then();
    (await this.popoverController.getTop())?.dismiss().then();
  }

  private async findPathToRedirect(): Promise<void> {
    await this.ngZone.run(() => {
      if (this.router.isActive(routeNames.expeditedReview, false)) {
        this.navController.navigateBack(routeNames.mainScreen);
        this.navController.navigateForward(routeNames.documents);
        return;
      }
      this.navController.navigateRoot(routeNames.documents).then();
    });
  }
}
