import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { UserInfoApiService } from '@npaApi/user-info.api.service';
import { UsersDataService } from '@npaCore/services/users-data.service';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { UserInfoResponseModel } from '@npaShared/models/user-info.model';
import { CurrentUserModel } from '@oogShared/models/current-user/current-user.model';
import { RelevantWorkersService } from '@oogShared/services/relevant-workers.service';
import { BaseAuthGuardService } from '@shared/services/base-auth-guard.service';
import { SettingsCertificateService } from '@shared/services/certificates/settings-certificate.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SettingsGuardService extends BaseAuthGuardService implements CanActivate {
  constructor(
    userApi: UserInfoApiService,
    userAissdStore: CurrentUserAissdStoreService,
    userIsproStore: CurrentUserIsproStoreService,
    authUserIspro: AuthUserIsproStoreService,
    store: Store,
    settingsCertificateService: SettingsCertificateService,
    relevantWorkersService: RelevantWorkersService,
    private navController: NavController,
    private userService: UsersDataService,
  ) {
    super(
      userApi,
      userIsproStore,
      authUserIspro,
      store,
      settingsCertificateService,
      userAissdStore,
      relevantWorkersService,
    );
  }

  public canActivate(): Observable<boolean> {
    return this.getUsers().pipe(
      switchMap(([res1, res2]) => {
        if (!res1 && !res2) {
          this.navController.navigateRoot('/auth/login').then();
          return of(false);
        }
        return of(true);
      }),
    );
  }

  private getUsers(): Observable<[UserInfoResponseModel, CurrentUserModel]> {
    const isPro$: Observable<CurrentUserModel | null> = this.userService.getOogUser$(true);
    const aisSd$: Observable<UserInfoResponseModel | null> = this.userService.getNpaUser$(true);

    return this.getUsersFromStoreOrApi(isPro$, aisSd$);
  }
}
