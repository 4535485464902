import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlsService {
  private localStorageName = 'hostUrl';

  public get getHost(): string {
    return localStorage.getItem('hostUrl');
  }

  public setUrls(url: string): void {
    localStorage.setItem(this.localStorageName, url);
  }
}
