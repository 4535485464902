import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';
import { of } from 'rxjs';
import { filter, first, map, mapTo, switchMap, takeUntil } from 'rxjs/operators';

import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ChoiceMainProjectModel } from '@oogShared/components/card-expedited-review/choice-main-project.model';
import { SideBarBase } from '@oogShared/components/side-bar-base';
import { SwipeDirectionEnum } from '@oogShared/components/side-bar-expedited-review/swipe-direction.enum';
import { ExpeditedReviewModel } from '@oogShared/models/expedited-review/expedited-review.model';
import { ExpeditedReviewInteropService } from '@oogShared/rest/expedited-review.interop.service';
import { MenuExpeditedReviewService } from '@oogShared/services/menu-expedited-review.service';
import {
  ResetActiveProject,
  SetActiveProject,
} from '@store/expedited-review/active-project/active-project-expedited-review.action';
import { ActiveProjectExpeditedReviewState } from '@store/expedited-review/active-project/active-project-expedited-review.state';
import { ChosenDocumentInGroupState } from '@store/expedited-review/choosen-document-in-group/chosen-document-in-group.state';
import {
  DeleteCardExpeditedReview,
  DeleteGroupExpeditedReview,
  ResetMenuExpeditedReview,
} from '@store/expedited-review/menu/menu-expedited-review.action';
import { MenuExpeditedReviewState } from '@store/expedited-review/menu/menu-expedited-review.state';
import { ResetRelevantWorkers } from '@store/main-screen/relevant-workers/relevant-workers.action';
import { ResetResolutionFiles } from '@store/resolution-store/resolution-files/resolution-files.action';
import { ResetResolutionTree } from '@store/resolution-store/resolution-tree/resolution-tree.action';

import { SwipeMenuService } from '@oogShared/services/swipe-menu.service';
import { DateHelperConst } from '@const/date-helper.const';
import { DateHelperService } from '@shared/services/date-helper.service';
import { routeNames } from '../../../../app-routing/route-names.const';

@Component({
  selector: 'app-side-bar-expedited-review',
  templateUrl: './side-bar-expedited-review.component.html',
  styleUrls: ['./side-bar-expedited-review.component.scss'],
})
export class SideBarExpeditedReviewComponent extends SideBarBase implements OnInit {
  @Input() public documentCards: ExpeditedReviewModel[] = [];

  public idActiveDocument: number | null = null;
  public selectedCards: { [key: number]: boolean } = {};

  constructor(
    store: Store,
    swipeMenu: SwipeMenuService,
    elementRef: ElementRef,
    public sidebar: MenuExpeditedReviewService,
    private navController: NavController,
    private expeditedInterop: ExpeditedReviewInteropService,
    private modalService: ModalService,
    private dateHelperService: DateHelperService,
  ) {
    super(store, swipeMenu, elementRef);
  }

  public ngOnInit(): void {
    this.subscribeToActiveCard();
    this.subscribeToChosenDocumentInGroup();
  }

  public actionSwipe(card: ExpeditedReviewModel, event: CustomEvent): void {
    const groupProject = card.resolutionDto.groupProjects;

    if (groupProject?.groupProjects.mainAppealMovementId !== groupProject?.appealMovementId) {
      return;
    }

    if (event?.detail?.side === SwipeDirectionEnum.End) {
      this.deleteProject(card);
    }

    if (event?.detail?.side === SwipeDirectionEnum.Start) {
      this.acceptProject(card);
    }
  }

  public previousPage(): void {
    this.navController.navigateBack([routeNames.mainScreen]).then(() => {
      this.store.dispatch([
        ResetMenuExpeditedReview,
        ResetRelevantWorkers,
        ResetResolutionFiles,
        ResetResolutionTree,
        ResetActiveProject,
      ]);
      this.sidebar.loading$.next(true);
    });
  }

  public doRefresh(event?: any, isChooseFirstCard?: boolean): void {
    this.sidebar
      .refreshToMenuList()
      .pipe(first())
      .subscribe((result) => {
        this.setDataToStore(result, event);
        isChooseFirstCard && this.chooseFirstCard();
      });
  }

  /** Утвердить проект */
  public acceptProject(card: ExpeditedReviewModel): void {
    this.sideBarList.closeSlidingItems().then();
    this.expeditedInterop
      .getRightExpeditedReview()
      .pipe(
        first(),
        switchMap((right) => {
          if (right.data) {
            return this.expeditedInterop.acceptProjects(this.getProjectsForAccept([card]));
          }
          this.showModalError();
          return of({ data: null, success: false, error: null });
        }),
        filter((result) => result.success),
      )
      .subscribe(() => this.switchDocument(card));
  }

  /** Утвердить все проекты */
  public acceptAll(): void {
    this.sideBarList.closeSlidingItems().then();
    this.expeditedInterop
      .getMenu(true)
      .pipe(
        first(),
        switchMap((projects) => {
          const { activeProjects, countLostIds, isExistsNew } = this.getDataForAcceptAll(this.documentCards, projects);

          const acceptProjects$ = this.expeditedInterop.acceptProjects(activeProjects).pipe(
            map((result) => {
              if (!result?.success) {
                throw new Error('ошибка при утверждении проектов');
              }
              return result.success;
            }),
          );

          return (activeProjects.length ? acceptProjects$ : of(true)).pipe(mapTo({ countLostIds, isExistsNew }));
        }),
      )
      .subscribe(
        ({ countLostIds, isExistsNew }) => {
          if (countLostIds) {
            this.showModalPartAcceptProjects(countLostIds).then((confirm) => {
              isExistsNew ? this.doRefresh(undefined, true) : this.previousPage();
            });
          } else {
            isExistsNew ? this.doRefresh(undefined, true) : this.previousPage();
          }
        },
        () => this.showModalError(),
      );
  }

  public acceptSelected(): void {
    this.sideBarList.closeSlidingItems().then();

    const selectedIds = Object.entries(this.selectedCards).reduce(
      (acc, [key, value]) => (value ? [...acc, +key] : acc),
      [],
    );
    const cards = this.documentCards.filter((card) => selectedIds.includes(card.resolutionDto.id));
    this.expeditedInterop
      .acceptProjects(this.getProjectsForAccept(cards))
      .pipe(
        filter((result) => result.success),
        switchMap(() => this.sidebar.refreshToMenuList()),
        first(),
      )
      .subscribe((data) => {
        if (data.length > 0) {
          this.setDataToStore(data);
          this.chooseFirstCard();
          this.selectedCards = {};
        } else {
          this.previousPage();
        }
      });
  }

  /** Удалить проект из ускоренного рассмотрения */
  public deleteProject(card: ExpeditedReviewModel): void {
    this.sideBarList.closeSlidingItems().then();
    this.expeditedInterop
      .switchProject(false, card.resolutionDto.id)
      .pipe(
        filter((r) => r?.success),
        first(),
      )
      .subscribe(() => this.switchDocument(card));
  }

  public chooseCard(id: number): void {
    const activeId = this.store.selectSnapshot(ActiveProjectExpeditedReviewState.getActiveProjectId);
    if (activeId === id) {
      return;
    }
    this.store.dispatch([new SetActiveProject(id), ResetResolutionFiles, ResetResolutionTree]);
  }

  public chooseMainCardInGroup(data: ChoiceMainProjectModel): void {
    this.sideBarList.closeSlidingItems().then();

    const cards = this.store.selectSnapshot(MenuExpeditedReviewState.getExpeditedMenu);
    const index = cards.findIndex((c) => {
      const group = c.resolutionDto.groupProjects;
      return group?.groupProjects.id === data.id && group?.appealMovementId === data.mainAppealMovement;
    });
    const mainProjectId = cards[index]?.resolutionDto.id;
    const heightProjectCard = 219;

    this.store.dispatch(new SetActiveProject(mainProjectId));
    this.documentList?.scrollToPoint(0, index * heightProjectCard, 500).then();
    data.event.stopPropagation();
  }

  public toggleSelectedCard(card: ExpeditedReviewModel): void {
    this.sideBarList.closeSlidingItems().then();
    this.selectedCards[card.resolutionDto.id] = !this.selectedCards[card.resolutionDto.id];
  }

  public hasSelectedCard(): boolean {
    return Object.values(this.selectedCards).some((v) => v);
  }

  private subscribeToChosenDocumentInGroup(): void {
    this.store
      .select(ChosenDocumentInGroupState.getChosenDocument)
      .pipe(
        filter((r) => !!r),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((r) => this.chooseMainCardInGroup(r));
  }

  private setDataToStore(data: ExpeditedReviewModel[], event?: any): void {
    this.sidebar.mapNewObjectExpeditedReview(data);
    event?.target.complete();
  }

  private subscribeToActiveCard(): void {
    this.store
      .select(ActiveProjectExpeditedReviewState.getActiveProjectId)
      .pipe(
        filter((id) => !!id),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((id) => (this.idActiveDocument = id));
  }

  private determineIndexActiveCard(): void {
    const activeId = this.store.selectSnapshot(ActiveProjectExpeditedReviewState.getActiveProjectId);
    const cards = this.store.selectSnapshot(MenuExpeditedReviewState.getExpeditedMenu);
    const index = cards.findIndex((c) => c.resolutionDto.id === activeId);
    if (cards.length === 1) {
      this.previousPage();
      return;
    }
    index + 1 === cards.length ? this.chooseFirstCard() : this.chooseNextCard();
  }

  private chooseNextCard(): void {
    const id = this.store.selectSnapshot(MenuExpeditedReviewState.getNextProjectId);
    this.store.dispatch(new SetActiveProject(id));
  }

  private chooseFirstCard(): void {
    const id = this.store.selectSnapshot(MenuExpeditedReviewState.getFirstProjectId);
    this.store.dispatch(new SetActiveProject(id));
    this.documentList?.scrollToPoint(0, 0, 500).then();
  }

  private turnOnFirstProject(): void {
    const cards = this.store.selectSnapshot(MenuExpeditedReviewState.getExpeditedMenu);
    if (!cards.length) {
      this.previousPage();
      return;
    }
    this.store.dispatch(new SetActiveProject(cards[0].resolutionDto.id));
  }

  private switchDocument(card: ExpeditedReviewModel): void {
    const group = card.resolutionDto.groupProjects;
    if (group) {
      this.store.dispatch(new DeleteGroupExpeditedReview(group.groupProjects.mainAppealMovementId));
      this.turnOnFirstProject();
      return;
    }
    this.determineIndexActiveCard();
    this.store.dispatch(new DeleteCardExpeditedReview(card.resolutionDto.id));
  }

  /** Возвращает главную и все дочерние карточки
   *
   * @param mainCardId - id главной карточки в группового утверждения
   * */
  private getCardsForGroup(mainCardId: number): ExpeditedReviewModel[] {
    return this.documentCards.filter(
      (card) => card.resolutionDto.groupProjects?.groupProjects.mainAppealMovementId === mainCardId,
    );
  }

  /**
   * Подготовить проекты для отправки на утверждение, если выбран главный в групповом, будут добавлены все дочерние
   *
   * @param cards выбранные документы для утверждения
   * @private
   */
  private getProjectsForAccept(cards: ExpeditedReviewModel[]): ExpeditedReviewModel[] {
    return cards.reduce((acc, el) => {
      const isMainCardInGroup =
        el.resolutionDto.groupProjects?.groupProjects.mainAppealMovementId === el.appealMovementId;
      if (isMainCardInGroup) {
        return [...acc, ...this.getCardsForGroup(el.appealMovementId)];
      }
      return [...acc, el];
    }, []);
  }

  /**
   * Через "Утвердить все" отправляем только те, которые есть в данный момент в реестре
   * и для них НЕсняли флаг УР
   *
   * @param currentProjects
   * @param actualProjects
   * @private
   */
  private getDataForAcceptAll(
    currentProjects: ExpeditedReviewModel[],
    actualProjects: ExpeditedReviewModel[],
  ): {
    activeProjects: ExpeditedReviewModel[];
    countLostIds: number;
    isExistsNew: boolean;
  } {
    if (!currentProjects || !actualProjects) {
      throw new Error('нет данных для утверждения');
    }

    const mapToIds = (data: ExpeditedReviewModel[]): number[] =>
      data
        ?.map((item) => item?.resolutionDto?.id)
        ?.filter(Boolean)
        ?.map((item) => +item)
        ?.sort() ?? [];
    const currentIds = mapToIds(currentProjects);
    const actualIds = mapToIds(actualProjects);

    const activeProjectsIds = [];
    const newProjectsIds = [];

    for (const item of actualIds) {
      if (currentIds.includes(item)) {
        activeProjectsIds.push(item);
      } else {
        newProjectsIds.push(item);
      }
    }

    const mapToCurrent = (data: ExpeditedReviewModel[], ids: number[]): ExpeditedReviewModel[] =>
      data.filter((card) => ids.includes(card.resolutionDto.id));

    const countLostIds = Math.abs(currentIds.length - activeProjectsIds.length);
    const isExistsNew = !!newProjectsIds.length;
    const activeProjects = !countLostIds
      ? currentProjects
      : this.getProjectsForAccept(mapToCurrent(actualProjects, activeProjectsIds));

    return { activeProjects, countLostIds, isExistsNew };
  }

  private showModalError(): void {
    const message = 'У вас нет прав для просмотра проектов, направленных на ускоренное рассмотрение';
    this.modalService.presentModalError(message, false).then((result) => {
      !result && this.previousPage();
    });
  }

  private showModalPartAcceptProjects(count: number): Promise<boolean> {
    const message = `При выполнении операции "Утвердить все" в ${this.dateHelperService.dateFormatting(
      new Date().toLocaleString(),
      DateHelperConst.timeDateFormat,
    )} не утвердилось ${count} проекта(-ов), т.к. они были сняты с ускоренного рассмотрения`;
    return this.modalService
      .presentModalWithAlarmIcon('Внимание!', message, 'ОБНОВИТЬ')
      .then((modal) => modal.onDidDismiss())
      .then(() => true);
  }
}
