import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { CodeErrorsEnum } from '@oogShared/enums/code-errors.enum';
import { MenuHelperService } from '@oogShared/services/menu-helper.service';
import { ResetSettingsAcceleratedReview } from '@store/settings/settings.action';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalService } from '@shared/services/modal.service';
import { SettingsMenuEnum } from '@enums/settings/settings.enum';
import { SettingsState } from '@store/settings/settings.state';
import { SettingsInteropService } from '@rest/settings-interop.service';
import { routeNames } from '../../../../app-routing/route-names.const';

/**
 * Перехватчик http запросов, показывает модалку
 */
@Injectable()
export class HttpInterceptorModalService implements HttpInterceptor {
  constructor(
    private modalService: ModalService,
    private modalCtrl: ModalController,
    private menuHelperService: MenuHelperService,
    private store: Store,
    private navCtrl: NavController,
    private settingsService: SettingsInteropService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (!event.body?.error) {
            return;
          }
          switch (event.body?.error.text) {
            case CodeErrorsEnum.acceptedProjectValidation:
              this.modalService.presentModalError(event.body.data[0], true).then((result) => {
                if (!result) {
                  return;
                }

                this.menuHelperService.refreshToMenuListAfterError();
              });
              break;
            case CodeErrorsEnum.confirmReview:
              this.modalService.presentModalError(event.body.data[0], true).then(() => {
                this.menuHelperService.refreshToMenuListAfterError();
              });
              break;
            case CodeErrorsEnum.acceleratedReview:
              this.modalService.presentModalError(event.body.data[0], false).then((result) => {
                !result && this.resetSettingsAcceleratedReview();
              });
              break;
            default:
              this.modalService.presentModalError(event.body.data[0]);
              break;
          }
        }
      }),
    );
  }

  private resetSettingsAcceleratedReview(): void {
    this.store.dispatch(ResetSettingsAcceleratedReview);

    const documentSettings = this.store.selectSnapshot(SettingsState.settingsDocuments);
    this.settingsService.saveSettings(SettingsMenuEnum.document, documentSettings);

    this.navCtrl.navigateBack(routeNames.mainScreen);
  }
}
