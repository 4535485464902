import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ButtonThemeEnum } from '@enums/button-theme.enum';
import { ModalController } from '@ionic/angular';
import { ButtonsTextEnum } from '@npaShared/enums/buttons-text.enum';

@Component({
  selector: 'app-refresh-current-route',
  templateUrl: './refresh-current-route.component.html',
  styleUrls: ['./refresh-current-route.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefreshCurrentRouteComponent {
  public readonly buttonTheme = ButtonThemeEnum;
  public readonly buttonsText = ButtonsTextEnum;

  constructor(private modalController: ModalController) {}

  public close(): void {
    this.modalController.dismiss();
  }
}
